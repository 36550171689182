const state = {
    LoginModel: 'LoginModel',
    AdminModel: 'AdminModel',
    CustomerModel: 'CustomerModel',
    PersonModel: 'PersonModel',
};
const getters = {
    getLoginModel(state) {
        return state.LoginModel
    },
    getAdminModel(state) {
        return state.AdminModel
    },
    getCustomerModel(state) {
        return state.CustomerModel
    },
    getPersonModel(state) {
        return state.PersonModel
    },
};
const mutations = {};
const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
}