import ReqJson from "@/resource/reqJson";

const state = {
    marketCategories: [],

    totalAdminCategoriesListCount: 0,
};

const getters = {
    getMarketCategories(state) {
        return state.marketCategories
    },
    getTotalAdminCategoriesCount(state) {
        return state.totalAdminCategoriesListCount
    },
};

const mutations = {
    setMarketCategories(state, marketCategories) {
        state.marketCategories = marketCategories
    },
    setTotalAdminCategoriesCount(state, totalAdminCategoriesListCount) {
        state.totalAdminCategoriesListCount = totalAdminCategoriesListCount
    },
};

const actions = {

    getMarketCategories({getters, commit, dispatch}, index) {

        if (index === 0)
            commit('setMarketCategories', [])

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/marketCategories', body: body})
            .then(response => {
                // context.commit("setMarketCategories", response.result);

                if (response.result.length > 0) {

                    if (index === 0)
                        commit("setTotalAdminCategoriesCount", response.meta.TotalCount);

                    for (let i = 0; i < response.result.length; i++) {
                        getters.getMarketCategories.push(response.result[i])
                    }

                    if (response.result.length === getters.getLimit) {
                        index = getters.getMarketCategories.length
                        dispatch('getMarketCategories', index)
                    }
                }

            })
    },

    addMarketCategory({getters}, item) {

        const mainObj = {
            'MarketCategory': {
                'ID': item.ID,
                'Title': item.Title
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/addCategory', body: body})
            .then((response) => {
                if (item.ID) {
                    for (let i = 0; i < getters.getMarketCategories.length; i++) {
                        if (getters.getMarketCategories[i].ID === item.ID) {
                            getters.getMarketCategories[i] = response.result[0]
                            break
                        }
                    }
                } else {
                    getters.getMarketCategories.push(response.result[0])
                }
            })
    },

    deleteMarketCategory({getters}, categoryID) {

        const mainObj = {
            'MarketCategory': {
                'ID': categoryID,
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/delCategory', body: body})
            .then(() => {
                for (let i = 0; i < getters.getMarketCategories.length; i++) {
                    if (getters.getMarketCategories[i].ID === categoryID) {
                        getters.getMarketCategories.splice(i, 1);
                        break
                    }
                }
            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
