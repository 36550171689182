import ReqJson from "@/resource/reqJson";
import {router} from '@/router/router'
import Vue from "vue";


const state = {
    customerProfile: {},
    organsSupport: [],
    documentList: [],
    uploadItems: []
};

const getters = {
    getCustomerProfile(state) {
        return state.customerProfile
    },
    getOrgansSupport(state) {
        return state.organsSupport
    },
    getDocumentList(state) {
        return state.documentList
    },
    getUploadItems(state) {
        return state.uploadItems
    },
};

const mutations = {
    setCustomerProfile(state, customerProfile) {
        state.customerProfile = customerProfile
    },
    setOrgansSupport(state, organsSupport) {
        state.organsSupport = organsSupport
    },
    setDocumentList(state, documentList) {
        state.documentList = documentList
    },
    setUploadItems(state, uploadItems) {
        state.uploadItems = uploadItems
    },
};

const actions = {

    getCustomerProfile({getters, commit}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/getProfile', body: body})
            .then(response => {

                    commit('setCustomerProfile', response.result[0])


                    if (getters.getCustomerProfile.ProfileCompleted === true) {
                        if (router.currentRoute.path !== '/customer/requests' && !router.currentRoute.path.includes('register') && !router.currentRoute.path.includes('addNewRequest')) {
                            router.push('/customer/requests')
                        }
                    } else {

                        getters.getCustomerProfile.BirthDate = ''
                        getters.getCustomerProfile.MilitaryStatus = ''
                        getters.getCustomerProfile.MaritalStatus = ''
                        getters.getCustomerProfile.Gender = ''
                        getters.getCustomerProfile.SupportOrgan = ''

                        if (router.currentRoute.path !== '/register/personalInfo') {
                            router.push('/register/personalInfo')
                            commit('setReserveRequest', {
                                selectedMarketCategory: '',
                                selectedMarketType: '',
                                selectedMarketID: '',
                                requestedDuration: '',
                                requestedText: '',
                                boothCount: '',
                            })
                        }
                    }
                }
            )
    },

    setCustomerProfile({getters, dispatch}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/setProfile', body: body})
            .then(() => {

                if (router.currentRoute.path.includes('register'))
                    router.push('/register/uploadDocsRequest')
                else
                    router.push('/customer/requests/addNewRequest/uploadDocsRequest')

                dispatch('getCustomerProfile')
            })
    },

    getOrgansSupport({getters, commit}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/getSupports', body: body})
            .then(response => {
                commit('setOrgansSupport', response.result)
            })
    },

    getIdDocumentList({getters, commit}) {

        commit('setUploadItems', [])
        commit("setDocumentList", [])

        const mainObj = {
            "IdDocument": {}
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/idDocumentList', body: body})
            .then(response => {
                commit('setDocumentList', response.result)

                for (let i = 0; i < getters.getDocumentList.length; i++) {
                    getters.getUploadItems.push({url: '', file: null, isUploaded: false})
                    if (getters.getCustomerProfile.IdDocuments !== undefined)
                        for (let j = 0; j < getters.getCustomerProfile.IdDocuments.length; j++) {
                            if (getters.getCustomerProfile.IdDocuments[j].ID === getters.getDocumentList[i].ID) {
                                getters.getUploadItems[i].url = getters.getCustomerProfile.IdDocuments[j].Attachment
                                getters.getUploadItems[i].isUploaded = true
                            }
                        }
                }


            })

    },

    uploadDocs({getters, dispatch}, data) {

        const fd = new FormData();

        fd.append(data.uploadDocID, data.pic, data.pic.name);

        this.dispatch('sendRequest', {url: 'Attachment/id_docs/upload', body: fd})
            .then(response => {
                getters.getUploadItems[data.index].url = response.result
                getters.getUploadItems[data.index].isUploaded = true
                dispatch('getCustomerProfile')
                Vue.toasted.success('بارگزاری مدرک انجام شد!', {duration: '1000'})
            })
    },

    uploadCustomerAvatar({getters}, pic) {

        const fd = new FormData();

        fd.append('avatar', pic, pic.name);

        this.dispatch('sendRequest', {url: 'Attachment/avatar/upload', body: fd})
            .then(response => {
                getters.getCustomerProfile.Avatar = response.result[0].FileID
                Vue.toasted.success('بارگزاری عکس انجام شد!', {duration: '1000'})
            })

    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
