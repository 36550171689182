<template>
  <div id="app" class="gray-bg">

    <popup-modal></popup-modal>

    <v-loading></v-loading>

    <transition enter-active-class="animate__animated animate__fadeIn">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>


import VLoading from "@/components/helper/vLoading";
import popupModal from "@/components/helper/modal/popupModal/popupModal";

export default {
  name: 'App',
  components: {popupModal, VLoading},
  beforeMount() {
    if (!this.$cookies.get(this.$store.getters.getACK) || this.$cookies.get(this.$store.getters.getACK) === 'null') {
      if (this.$router.currentRoute.path !== '/login')
        this.$router.replace({path: '/login'});
    } else {
      this.$store.dispatch('getProfile')
    }
  },

}
</script>

<style>

@import "./assets/css/normalize.css";
@import "./assets/css/fonts.css";
@import "./assets/css/grid.css";
@import "./assets/css/custom.css";
@import "./assets/css/style.css";

@import "./assets/css/login/login.css";
@import "./assets/css/dashboard/dashboard.css";
@import "./assets/css/dashboard/bazaar.css";
@import "./assets/css/dashboard/request.css";
@import "./assets/css/dashboard/financial.css";
@import "./assets/css/dashboard/systemUser.css";
@import "./assets/css/dashboard/report.css";
@import "./assets/css/dashboard/dashboardResponsive.css";

@import "./assets/css/market/requests.css";
@import "./assets/css/customer/register.css";
@import "./assets/css/customer/customer-responsive.css";
@import "./assets/css/market/market-dashboard-responsive.css";

</style>
