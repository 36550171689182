import { render, staticRenderFns } from "./confirmInfo.vue?vue&type=template&id=2446f00c&scoped=true&"
import script from "./confirmInfo.vue?vue&type=script&lang=js&"
export * from "./confirmInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2446f00c",
  null
  
)

export default component.exports