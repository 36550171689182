<template>

  <loading :active.sync="isShow"
           :on-cancel="onCancel"
           :is-full-page="fullPage"></loading>

</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "vLoading",
  data() {
    return {
      fullPage: true,
      t: null
    }
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {
    },
  },
  watch: {
    isShow: function () {
      if (this.isShow) {
        this.t = setTimeout(() => {
          this.$store.commit('setLoading', false)
        }, 1000000)
      } else {
        clearTimeout(this.t)
      }
    }
  },
  computed: {
    isShow() {
      return this.$store.getters.isLoading
    },
  }
}
</script>

<style scoped>

</style>