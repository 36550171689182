<template>
  <base-modal
      :show="isModalShow"
      :animation="'zoom'"
      @hide="$store.commit('setModalShow',false)"
      @clickMask="clickOnMask"
      @clickEsc="clickOnEsc"
      :closeOnEsc="false"
      :closeOnClickMask="false"
      :width="400"
      :height="230">

    <div class="popup-modal-container">

      <div><img :src="
      modalType === 'success' ? require('../../../../assets/images/success.png') :
      modalType === 'warning' ? require('../../../../assets/images/warning.png') :
      modalType === 'error' ? require('../../../../assets/images/error.png') :
      modalType === 'info' ? require('../../../../assets/images/info.png') : ''
      " width="70px" height="70px" alt="s">
      </div>

      <p>{{ modalTitle }}</p>

      <div class="popup-modal-btn-container">
        <button v-if="modalButton === 1" class="popup-modal-btn popup-modal-green-bg" v-on:click="clickOnOK">{{ ok }}</button>
        <button v-if="modalButton === 2" class="popup-modal-btn popup-modal-green-bg" v-on:click="clickOnYes">{{ yes }}</button>
        <button v-if="modalButton === 2" class="popup-modal-btn popup-modal-red-bg" v-on:click="clickOnNo">{{ no }}</button>
      </div>

    </div>

  </base-modal>
</template>

<script>

import {EventBus} from "@/components/helper/modal/popupModal/EventBus/EventBus";
import baseModal from "@/components/helper/modal/baseModal/baseModal";

export default {
  name: "popupModal",
  components: {
    baseModal
  },
  methods: {
    clickOnMask() {
    },
    clickOnEsc() {
    },
    clickOnOK() {
      this.$store.commit('setModalShow', false)
      EventBus.clickOK()
    },
    clickOnYes() {
      this.$store.commit('setModalShow', false)
      EventBus.clickYes()
    },
    clickOnNo() {
      this.$store.commit('setModalShow', false)
      EventBus.clickNo()
    },
  },
  computed: {
    isModalShow() {
      return this.$store.getters.isModalShow
    },
    modalTitle() {
      return this.$store.getters.getModalTitle
    },
    modalButton() {
      return this.$store.getters.getModalButton
    },
    modalType() {
      return this.$store.getters.getModalType
    },
    yes() {
      return this.$store.getters.getModalYesBtn
    },
    no() {
      return this.$store.getters.getModalNoBtn
    },
    ok() {
      return this.$store.getters.getModalOkBtn
    },
  },
  mounted() {
    // const self = this;
    window.addEventListener('keyup', function (event) {
      if (event.key === 'Enter') {
        // self.clickOnOK();
      }
    });
  },
}
</script>

<style scoped>

@import "style/popupStyle.css";

</style>