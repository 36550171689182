<template>

  <div>

    <div class="row justify-content-center step-container">
      <div class="col-md-8 d-flex">

        <div class="add-bazaar-step">
          <div class="add-bazaar-step-circle"
               :class="{'green-bg':$router.currentRoute.name === 'personalInfo'|| $router.currentRoute.name === 'confirmInfo' || $router.currentRoute.name === 'pay'}">
          </div>
          <p :class="{'green-color':$router.currentRoute.name === 'personalInfo' || $router.currentRoute.name === 'confirmInfo' || $router.currentRoute.name === 'pay'}">
            ثبت اطلاعات فردی</p>
        </div>

        <div class="add-bazaar-step-line"
             :class="{'green-bg': $router.currentRoute.name === 'confirmInfo' || $router.currentRoute.name === 'pay'}">
        </div>

        <div class="add-bazaar-step">
          <div class="add-bazaar-step-circle"
               :class="{'green-bg': $router.currentRoute.name === 'confirmInfo'  || $router.currentRoute.name === 'pay'}">
          </div>
          <p :class="{'green-color': $router.currentRoute.name === 'confirmInfo'  || $router.currentRoute.name === 'pay'}">
            تایید اطلاعات</p>
        </div>

        <div class="add-bazaar-step-line"
             :class="{'green-bg':$router.currentRoute.name === 'pay'}">
        </div>

        <div class="add-bazaar-step">
          <div class="add-bazaar-step-circle"
               :class="{'green-bg':$router.currentRoute.name === 'pay'}">
          </div>
          <p :class="{'green-color':$router.currentRoute.name === 'pay'}">پرداخت</p>
        </div>

      </div>
    </div>

    <transition enter-active-class="animate__animated animate__fadeIn">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";


export default {
  name: "registerCustomer",
  data() {
    return {
      routeName: 'personalInfo'
    }
  },
  methods:{

  },
  mixins: [
    constMethods
  ],
}
</script>

<style scoped>

</style>