import {store} from '@/store/store'

class ReqJson {
    constructor(_model, _object, _filter, _limit, _index) {
        {
            this.jsonObject = {
                object: _model,
                reference: "",
                from: 'tc',
                ts: Math.floor(Number(Date.now()) / 1000),
                data: {
                    filter: _filter,
                    limit: _limit,
                    index: _index
                },
                appkey: store.getters.getAppKey
            }
            this.jsonObject.data[_model] = _object
        }
    }

    reqJS() {
        return this.jsonObject;
    }

}

export default ReqJson