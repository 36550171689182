export const constMethods = {
    methods: {
        convertNumbersToEnglish: function (value) {
            if (value !== undefined && value !== null) {
                value = value.toString()
                return value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
                    return c.charCodeAt(0) & 0xf;
                });
            }
            return ''
        },

        isNumber(val) {
            if (val === undefined || val === "") {
                return true
            } else {
                let res = this.convertNumbersToEnglish(val)
                return !isNaN(res)
            }
        },

        convertJalaliToGregorian(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'fa', 'YYYY/MM/DD').format('YYYY-MM-DD') : '';
        },

        convertFormat(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY/MM/DD').format('YYYY-MM-DD') : '';
        },

        getYear(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY/MM/DD').format('jYYYY') : '';
        },
        getMonth(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY/MM/DD').format('jMM') : '';
        },
        getDay(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY/MM/DD').format('jDD') : '';
        },

        convertGregorianToJalali(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY-MM-DD').format('jYYYY/jMM/jDD') : '';
        },

        convertToRegularGregorian(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        },


        convertToRegularTime(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : '';
        },

        convertToRegularTime2(date) {
            return date !== undefined && date !== null && date !== '' ? this.$moment.from(date, 'en', 'HH:mm:ss').format('HH:mm') : '';
        },

        thousandSeparator(amount) {
            if (amount !== undefined && amount !== null && amount !== '' && amount !== 0 && amount !== '0') {
                return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        getDaysUntilTheEndOfJalaliMonth(GregorianDate) {
            let res = []
            let currDate = this.$moment(GregorianDate).startOf('day').add(0, 'days');

            res.push(this.convertGregorianToJalali(currDate))

            for (; ;) {
                currDate = currDate.add(1, 'days')
                if (this.getMonth(this.convertJalaliToGregorian(res[res.length - 1])) === this.getMonth(currDate))
                    res.push(this.convertGregorianToJalali(currDate))
                else break
            }
            return res
        },
        getTheLastDayOfJalaliMonth(GregorianDate) {
            let res = []
            let currDate = this.$moment(GregorianDate).startOf('day').add(0, 'days');

            res.push(this.convertGregorianToJalali(currDate))

            for (; ;) {
                currDate = currDate.add(1, 'days')
                if (this.getMonth(this.convertJalaliToGregorian(res[res.length - 1])) === this.getMonth(currDate))
                    res.push(this.convertGregorianToJalali(currDate))
                else break
            }
            return res[res.length - 1]
        },
        getPersianMonth(month) {
            switch (month) {
                case '01' || '1':
                    return 'فروردین'
                case '02' || '2':
                    return 'اردیبهشت'
                case '03' || '3':
                    return 'خرداد'
                case '04' || '4':
                    return 'تیر'
                case '05' || '5':
                    return 'مرداد'
                case '06' || '6':
                    return 'شهریور'
                case '07' || '7':
                    return 'مهر'
                case '08' || '8':
                    return 'آبان'
                case '09' || '9':
                    return 'آذر'
                case '10' || '10':
                    return 'دی'
                case '11' || '11':
                    return 'بهمن'
                case '12' || '12':
                    return 'اسفند'
            }

        },

        date_function: function () {

            // var currentDate = new Date();
            //
            // var formatted_date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
            //
            //
            // var formatted_date2 = new Date().toLocaleString();
            //
            // const current = new Date();
            // const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds() + "/" + current.getMilliseconds();
            // const milliSecond = current.getTime();
            // const day = current.getDay();
            // const dateTime = date + ' ' + time + '||' + milliSecond + '||' + day;
            //
            // var someDate = new Date('2021/5/28');
            // var numberOfDaysToAdd = 6;
            // someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            // console.log(someDate.getFullYear() + '-' + (someDate.getMonth() + 1) + '-' + someDate.getDate())
            //
            // console.log(this.$moment().add(5, 'days'))
        },

        onClickPic(n) {
            window.open(this.$store.getters.getImageServerURL + n, '_blank')
        },
    }
}


