import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import {router} from '@/router/router'
import 'animate.css'
import {store} from '@/store/store'
import '@/resource/resource'
// import '@/resource/axios'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);

Vue.use(vue_jalali_moment);
import vue_jalali_moment from "vue-jalali-moment";

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
    position:'top-center',
    duration: '4000'
})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
