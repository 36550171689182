import ReqJson from "@/resource/reqJson";
import {router} from '@/router/router'
import Vue from "vue";

const state = {
    customerAllBooths: [],
    totalCustomerAllBoothsCount: 0,

    customerBooths: [],
    totalCustomerBoothsCount: 0,

    datesByBoothID: [],

    customerSelectedBooth: {}
};

const getters = {
    getCustomerAllBooths(state) {
        return state.customerAllBooths
    },
    getTotalCustomerAllBoothsCount(state) {
        return state.totalCustomerAllBoothsCount
    },

    getCustomerBooths(state) {
        return state.customerBooths
    },
    getTotalCustomerBoothsCount(state) {
        return state.totalCustomerBoothsCount
    },

    getDatesByBoothID(state) {
        return state.datesByBoothID
    },
    getCustomerSelectedBooth(state) {
        return state.customerSelectedBooth
    },
};

const mutations = {
    setCustomerAllBooths(state, customerAllBooths) {
        state.customerAllBooths = customerAllBooths
    },
    setTotalCustomerAllBoothsCount(state, totalCustomerAllBoothsCount) {
        state.totalCustomerAllBoothsCount = totalCustomerAllBoothsCount
    },

    setCustomerBooths(state, customerBooths) {
        state.customerBooths = customerBooths
    },
    setTotalCustomerBoothsCount(state, totalCustomerBoothsCount) {
        state.totalCustomerBoothsCount = totalCustomerBoothsCount
    },

    setDatesByBoothID(state, datesByBoothID) {
        state.datesByBoothID = datesByBoothID
    },
    setCustomerSelectedBooth(state, customerSelectedBooth) {
        state.customerSelectedBooth = customerSelectedBooth
    },
};

const actions = {

    getAllBoothsCustomer({getters, commit, dispatch}, data) {

        const mainObj = {
            Booth: {
                MarketID: data.marketID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/getBooths', body: body})
            .then(response => {

                if (data.index === 0) {
                    commit('setCustomerAllBooths', [])
                    commit("setTotalCustomerAllBoothsCount", response.meta.TotalCount);
                }

                for (let i = 0; i < response.result.length; i++)
                    getters.getCustomerAllBooths.push({
                        ...response.result[i],
                        selected: false
                    })


                if (response.result.length === getters.getLimit) {
                    data.index = getters.getCustomerAllBooths.length
                    dispatch('getAllBoothsCustomer', data)
                }

            })
    },

    searchBoothsByDate({getters, commit, dispatch}, data) {

        const mainObj = {
            ReserveRequest: {
                ID: data.requestID
            }
        }

        const filter = {
            StartDate: data.startDate,
            EndDate: data.endDate,
        }

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'v2/Customer/boothSearchByDate', body: body})
            .then(response => {

                if (data.index === 0) {
                    commit('setCustomerBooths', [])
                    commit("setTotalCustomerBoothsCount", response.meta.TotalCount);
                }

                for (let i = 0; i < response.result.length; i++)
                    getters.getCustomerBooths.push(response.result[i])

                if (response.result.length === getters.getLimit) {
                    data.index = getters.getCustomerBooths.length
                    dispatch('searchBoothsByDate', data.index)
                }

                if (data.index === 0 && response.result.length === 0)
                    Vue.toasted.error('غرفه ای یافت نشد!', {duration: '1000'})


            })
    },

    searchDatesByBoothID({getters, commit}, data) {

        const mainObj = {
            ReserveRequest: {
                ID: data.requestID
            }
        }

        const filter = {
            Booths: data.boothIDs,
            StartDate: data.startDate,
            EndDate: data.endDate,
        }

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'v2/Customer/boothSearchByID', body: body})
            .then(response => {
                commit('setDatesByBoothID', response.result)
            })
    },

    reserveBooth({getters}, data) {

        const mainObj = {
            "Booth": {
                "ID": data.boothID
            },
            "ReserveRequest": {
                "ID": data.requestID
            },
            "Reservation": {
                "StartDate": data.startDate,
                "EndDate": data.endDate
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'v2/Customer/boothReservation', body: body})
            .then(response => {

                router.push({path: '/customer/requests/customerFactor', query: {reserveID: response.result[0].ID}})

            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
