import ReqJson from "@/resource/reqJson";
import Vue from "vue";

const state = {
    violationsList: [],
    totalViolationsCount: 0,
    violationItemsList: [],
    totalViolationItemsCount: 0,
};

const getters = {
    getViolationsList(state) {
        return state.violationsList
    },
    getTotalViolationsCount(state) {
        return state.totalViolationsCount
    },
    getViolationItemsList(state) {
        return state.violationItemsList
    },
    getTotalViolationItemsCount(state) {
        return state.totalViolationItemsCount
    },
};

const mutations = {
    setViolationsList(state, violationsList) {
        state.violationsList = violationsList
    },
    setTotalViolationsCount(state, totalViolationsCount) {
        state.totalViolationsCount = totalViolationsCount
    },
    setViolationItemsList(state, violationItemsList) {
        state.violationItemsList = violationItemsList
    },
    setTotalViolationItemsCount(state, totalViolationItemsCount) {
        state.totalViolationItemsCount = totalViolationItemsCount
    },
};

const actions = {

    getViolations({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/violation/violationReports', body: body})
            .then(response => {
                commit("setViolationsList", response.result);
                if (data.index === 0) {
                    commit("setTotalViolationsCount", response.meta.TotalCount);
                }
            })
    },

    getViolationItems({getters, commit}, index) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/violation/violationItems', body: body})
            .then(response => {
                commit("setViolationItemsList", response.result);
                if (index === 0) {
                    commit("setTotalViolationItemsCount", response.meta.TotalCount);
                }
            })
    },

    addViolationItem({getters, dispatch}, violationTitle) {

        const mainObj = {
            'Violation': {
                'Title': violationTitle
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/violation/addViolationItem', body: body})
            .then(() => {
                dispatch('getViolationItems', 0)
            })
    },

    deleteViolationItem({getters, dispatch}, violationID) {

        const mainObj = {
            'Violation': {
                'ID': violationID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/violation/delViolationItem', body: body})
            .then(() => {
                dispatch('getViolationItems', 0)
            })
    },

    sendViolationNotice({getters}, violationID) {

        const mainObj = {
            'ViolationReport': {
                'ID': violationID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/violation/violationNotice', body: body})
            .then((response) => {
                Vue.toasted.success('پیام اخطاریه ارسال شد!', { /* some option */})

                for (let i = 0; i < getters.getViolationsList.length; i++) {
                    if (getters.getViolationsList[i].ID === violationID) {
                        getters.getViolationsList[i] = response.result[0]
                    }
                }
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
