import ReqJson from "@/resource/reqJson";
import {router} from '@/router/router'

const state = {
    customerRequestsList: [],
    totalCustomerRequestsList: 0,
    selectedCustomerRequest : {}
};

const getters = {
    getCustomerRequestsList(state) {
        return state.customerRequestsList
    },
    getTotalCustomerRequestsList(state) {
        return state.totalCustomerRequestsList
    },
    getSelectedCustomerRequest(state) {
        return state.selectedCustomerRequest
    },
};

const mutations = {
    setCustomerRequestsList(state, customerRequestsList) {
        state.customerRequestsList = customerRequestsList
    },
    setTotalCustomerRequestsList(state, totalCustomerRequestsList) {
        state.totalCustomerRequestsList = totalCustomerRequestsList
    },
    setSelectedCustomerRequest(state, selectedCustomerRequest) {
        state.selectedCustomerRequest = selectedCustomerRequest
    },
};

const actions = {


    setRequestReserve({getters}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/requestReserve', body: body})
            .then(() => {

                router.push('/customer/requests')
            })
    },

    getCustomerRequests({getters, commit}, index) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/myReserveReqs', body: body})
            .then(response => {
                commit("setCustomerRequestsList", response.result);
                if (index === 0) {
                    commit("setTotalCustomerRequestsList", response.meta.TotalCount);
                }
            })
    },

    cancelCustomerRequest({getters, dispatch}, requestID) {

        const mainObj = {
            "ReserveRequest": {
                "ID": requestID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/cancelRequest', body: body})
            .then(() => {
                dispatch('getCustomerRequests', 0)
            })
    },

    getCustomerRequestDetails({getters, commit}, reserveRequestID) {

        commit("setRequestDetail",  {Customer:{SupportOrgan:{}, IdDocuments:[]}, ReserveRequest:{Market:{MarketType:{},MarketCategory:{}}}});

        const object = {};
        object['ReserveRequest'] = {
            'ID': reserveRequestID
        }

        const mainObj = object


        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/requestDetail', body: body})
            .then(response => {
                commit("setRequestDetail", response.result[0]);
            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
