<template>
  <div>

    <div class="row">
      <div class="col-xl-6 col-md-10 col-sm-12 bg-panel">

        <div class="d-flex">

          <div class="bg-panel-child bg-panel-child-name">
            <p class="bg-panel-doc-name m-0">دکتر {{ profile.Name }}</p>
            <div class="d-flex justify-content-center align-items-center w-100" style="height: 100%">
              <p class="bg-panel-doc-family m-0">{{ profile.Family }}</p>
            </div>
          </div>

          <div class="bg-panel-child bg-panel-child-name bg-panel-child-expertise">
            <div class="expertise-container">
              <p class="text-expertise">{{ profile.Expertise.Sub.FaName }}</p>
            </div>
          </div>

        </div>

        <div class="d-flex justify-content-between align-items-center flex-wrap">

          <p class="doc-name-latin">{{ profile.Expertise.Sub.EnName }}</p>


          <p class="doc-name-latin">DR.{{ profile.NameEN + " " + profile.FamilyEN }}</p>
        </div>

      </div>
    </div>


    <div class="customer-profile-main-container input-row" style="margin-top: 20px">

      <label class="label-input2">مشخصات تابلو</label>

      <div class="customer-profile-personal-info my-txt d-flex justify-content-between">

        <div class="col-xl-3 col-md-4 col-sm-6 text-align-right">
          <p class="primary-dark-color">سایز تابلو: ۳۳*۸۰ سانتیمتر</p>
        </div>

        <div class="col-xl-3 col-md-4 col-sm-6 text-align-center">
          <p class="primary-dark-color">رنگ تابلو: آبی</p>
        </div>

        <div class="col-xl-3 col-md-4 col-sm-6 text-align-left">
          <p class="primary-dark-color">جنس تابلو: فلزی شبرنگ</p>
        </div>
      </div>

    </div>

    <div class="d-flex justify-content-end" style="margin-top: 20px">
      <div class="btn green-bg width-160 d-flex align-items-center justify-content-center" v-on:click="nextStep">
        <p class="btn-title">تایید و ادامه</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "confirmInfo",
  methods: {
    nextStep() {
      this.$store.dispatch('setModal', {'title': 'آیا اطمینان دارید؟', 'type': 'warning', 'button': 2}).then(
          () => {
            this.$store.dispatch('setSReq')
          })
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    },
  }
}
</script>

<style scoped>

</style>