import ReqJson from "@/resource/reqJson";

const state = {
    customerCommutingList: [],
    totalCustomerCommutingListCount: 0,

};

const getters = {
    getCustomerCommutingList(state) {
        return state.customerCommutingList
    },
    getCustomerCommutingListCount(state) {
        return state.totalCustomerCommutingListCount
    },
};

const mutations = {
    setCustomerCommutingList(state, customerCommutingList) {
        state.customerCommutingList = customerCommutingList
    },
    setCustomerCommutingListCount(state, totalCustomerCommutingListCount) {
        state.totalCustomerCommutingListCount = totalCustomerCommutingListCount
    },
};

const actions = {

    getCustomerCommutingList({getters, commit}, data) {

        const mainObj = {
            "Reservation": {
                "ID": data.reserveID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/myCommutingReport', body: body})
            .then(response => {
                commit('setCustomerCommutingList', response.result)
                if (data.index === 0)
                    commit("setCustomerCommutingListCount", response.meta.TotalCount);
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
