<template>
  <div>

    <div class="customer-factor-container">

      <div class="customer-factor-header">
        <p>فاکتور پرداخت</p>
      </div>

      <div class="customer-factor-body list-info-container">


        <div v-for="(item,index) in sReq.ChargeItems" v-bind:key="index">
          <div class="d-flex justify-content-between">
            <p>{{item.Description}}:</p>
            <p>{{ thousandSeparator(item.Price) }} ریال</p>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <p>هزینه ساخت تابلو:</p>
          <p>{{ thousandSeparator(sReq.TotalPrice) }} ریال</p>
        </div>

      </div>
    </div>

    <div class="customer-factor-container notice-container" style="margin-top: 20px">

        <p style="font-size: 12px !important;">با اطلاع و آگاهی کامل و مطالعه دقیق مطالب و مباحث ذیل را تایید میکنم:</p>
        <p>- هر پزشک یا مشاغل وابسته به پزشکی و حوزه درمان مجاز به ثبت یک عدد تابلو در محل مشخص شده توسط سازمان سیما، منظر و فضای سبز شهری شهرداری یزد می‌باشد</p>
        <p>- هر پزشک یا مشاغل وابسته نمی‌تواند به صورت مجزا و خارج از محل‌های مشخص شده از طرف سازمان سیما، منظر و فضای سبز شهری جهت نصب تابلو، استفاده نماید</p>
        <p>- رعایت استانداردهای معرفی شده از طرف سازمان سیما، منظر و فضای سبز شهری توسط پزشک یا مشاغل وابسته الزامی است</p>
        <p>- جنس تابلو: فلزی یا هر نوع متریالی که سازمان سیما، منظر و فضای سبز شهری تایید نماید</p>
        <p>- ابعاد تابلو: 33*80 (طبق نمونه مرحله 2 ثبت نام)</p>
        <p>- ثبت نام دارای 4 مرحله بوده که میبایست هر مرحله توسط ناظرین سازمان سیما، منظر و فضای سبز شهری و فرد متقاضی (پزشک) تایید شود</p>
        <p>- در صورت هرگونه تخریب، آسیب و صدمه یا مواردی که منجر به تعویض تابلو گردد، متقاضی موظف است همکاری کامل با سازمان سیما، منظر و فضای سبز شهری را انجام داده و هزینه های مربوطه را پرداخت نماید</p>

    </div>




    <div class="d-flex justify-content-end" style="margin-top: 20px">
      <div class="btn green-bg width-160 d-flex align-items-center justify-content-center" v-on:click="nextStep">
        <p class="btn-title">تایید و پرداخت</p>
      </div>
    </div>

  </div>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "pay",
  mixins: [
    constMethods
  ],
  methods: {
    nextStep() {
      this.$store.dispatch('setModal', {'title': 'آیا اطمینان دارید؟', 'type': 'warning', 'button': 2}).then(
          () => {
            this.$store.dispatch('paySReq')
          })
    }
  },
  computed: {
    sReq() {
      return this.$store.getters.getSReq
    },
  }
}
</script>

<style scoped>

</style>