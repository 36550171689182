import login from "@/components/login/login";
import enterPhoneNumber from "@/components/login/enterPhoneNumber";
import enterVerificationCode from "@/components/login/enterVerificationCode";

import registerComponent from "@/components/register/registerComponent";
import personalInfo from "@/components/register/sections/personalInfo";
import confirmInfo from "@/components/register/sections/confirmInfo";
import pay from "@/components/register/sections/pay";
import receipt from "@/components/register/sections/receipt";
import mainDashboard from "@/components/dashboard/mainDashboard";
import requests from "@/components/dashboard/sections/content/body/requests";

export const Routes = [

    {
        path: '/',
        component: mainDashboard,
        children: [
            {
                name: 'requests',
                path: 'requests',
                component: requests,
            },
        ]
    },

    {
        path: '/register',
        component: registerComponent,
        children: [
            {
                name: 'personalInfo',
                path: 'personalInfo',
                component: personalInfo,
            },
            {
                name: 'confirmInfo',
                path: 'confirmInfo',
                component: confirmInfo,
            },
            {
                name: 'pay',
                path: 'pay',
                component: pay,
            },
        ]
    },

    {
        name: 'receipt',
        path: '/receipt',
        component: receipt,
    },

    {
        path: '/login',
        component: login,
        children: [
            {
                name: 'enterPhoneNumber',
                path: '',
                component: enterPhoneNumber,
            },
            {
                name: 'enterVerificationCode',
                path: 'enterVerificationCode',
                component: enterVerificationCode,
                props: true,
            },
        ]
    },

]