import ReqJson from "@/resource/reqJson";
import {router} from "@/router/router";

const state = {
    allMarketsList: [],
    marketTypes: [],
    selectedMarket: {},
    totalMarketsCount: 0,
    filteredMarketsList: [],
    dailyHours: [],
    totalDailyHours: 0,
};

const getters = {
    getAllMarketsList(state) {
        return state.allMarketsList
    },
    getMarketTypes(state) {
        return state.marketTypes
    },
    getSelectedMarket(state) {
        return state.selectedMarket
    },
    getTotalMarketsCount(state) {
        return state.totalMarketsCount
    },
    getFilteredMarketsList(state) {
        return state.filteredMarketsList
    },
    getDailyHours(state) {
        return state.dailyHours
    },
    getTotalDailyHoursCount(state) {
        return state.totalDailyHours
    },
};

const mutations = {
    setAllMarketsList(state, allMarketsList) {
        state.allMarketsList = allMarketsList
    },
    setMarketTypes(state, marketTypes) {
        state.marketTypes = marketTypes
    },
    setSelectedMarket(state, selectedMarket) {
        state.selectedMarket = selectedMarket
    },
    setTotalMarketsCount(state, totalMarketsCount) {
        state.totalMarketsCount = totalMarketsCount
    },
    setFilteredMarketsList(state, filteredMarketsList) {
        state.filteredMarketsList = filteredMarketsList
    },
    setDailyHours(state, dailyHours) {
        state.dailyHours = dailyHours
    },
    setTotalDailyHoursCount(state, totalDailyHours) {
        state.totalDailyHours = totalDailyHours
    },
};

const actions = {

    getMarkets({getters, commit, dispatch}, data) {

        if (data.index === 0) {
            commit('setAllMarketsList', [])
            commit('setFilteredMarketsList', [])
        }

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/markets', body: body})
            .then(response => {

                if (data.index === 0)
                    commit("setTotalMarketsCount", response.meta.TotalCount);

                for (let i = 0; i < response.result.length; i++)
                    getters.getAllMarketsList.push(response.result[i])

                if (response.result.length === getters.getLimit) {
                    data.index = getters.getAllMarketsList.length
                    dispatch('getMarkets', data)
                } else {
                    commit('setFilteredMarketsList', getters.getAllMarketsList)
                }

            })
    },

    setMarket({getters, commit}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/setMarket', body: body})
            .then(response => {

                commit("setSelectedMarket", response.result[0])
                getters.getAllMarketsList.push(response.result[0])
                commit("setTotalMarketsCount", getters.getTotalMarketsCount + 1);

                // router.push('/bazaar/addBazaar/addBooth')
                // or
                router.push({name: 'addBooth', query: {status: 'add'}})
            })
    },

    cloneMarket({getters, commit}, data) {

        const mainObj = data
        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/cloneMarket', body: body})
            .then(response => {
                getters.getAllMarketsList.push(response.result[0])
                commit("setTotalMarketsCount", getters.getTotalMarketsCount + 1);
            })
    },

    editMarket({getters, commit}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/editMarket', body: body})
            .then(response => {

                commit("setSelectedMarket", response.result[0]);

                for (let i = 0; i < getters.getAllMarketsList.length; i++) {
                    if (getters.getAllMarketsList[i].ID === response.result[0].ID) {
                        getters.getAllMarketsList[i] = response.result[0]
                        break
                    }
                }

                // router.push('/bazaar/addBazaar/addBooth')
                // or
                router.push({name: 'addBooth', query: {status: 'edit'}})

            })
    },

    getMarketTypes(context) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(context.rootGetters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/marketTypes', body: body})
            .then(response => {
                context.commit("setMarketTypes", response.result);
            })
    },


    switchMarketStatus({getters}, market) {
        const object = {};
        object['Market'] = {
            "ID": market.ID,
            "Active": market.Active == 1 ? 0 : 1
        }

        const mainObj = object
        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/switchMarketStatus', body: body})
            .then(() => {

                for (let i = 0; i < getters.getAllMarketsList.length; i++) {
                    if (getters.getAllMarketsList[i].ID === market.ID) {
                        getters.getAllMarketsList[i].Active = getters.getAllMarketsList[i].Active == 1 ? 0 : 1
                        break
                    }
                }
            })
    },

    getMarketDailyHours({getters, commit}, data) {

        if (data.index === 0) {
            commit("setDailyHours", []);
        }

        const mainObj = {};
        mainObj['Market'] = {
            "ID": data.marketID,
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/marketDailyHours', body: body})
            .then(response => {
                commit("setDailyHours", response.result);

                if (data.index === 0) {
                    commit("setTotalDailyHoursCount", response.meta.TotalCount);
                }
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
