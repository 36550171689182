import ReqJson from "@/resource/reqJson";
import {router} from '@/router/router'
import Vue from "vue";
import {store} from "@/store/store";

const state = {
    profile: {Address: {}, Expertise: {}},
    mainStreets: [],
    subStreets: [],
    majorExs: [],
    subExs: [],
    sReq: {},
    sReports: [],
    sTotalReports: 0
};

const getters = {
    getProfile(state) {
        return state.profile
    },
    getMainStreets(state) {
        return state.mainStreets
    },
    getSubStreets(state) {
        return state.subStreets
    },
    getMajorExs(state) {
        return state.majorExs
    },
    getSubExs(state) {
        return state.subExs
    },
    getSReq(state) {
        return state.sReq
    },
    getSReports(state) {
        return state.sReports
    },
    getSTotalReports(state) {
        return state.sTotalReports
    },
};

const mutations = {
    setProfile(state, profile) {
        state.profile = profile
    },
    setMainStreets(state, mainStreets) {
        state.mainStreets = mainStreets
    },
    setSubStreets(state, subStreets) {
        state.subStreets = subStreets
    },
    setMajorExs(state, majorExs) {
        state.majorExs = majorExs
    },
    setSubExs(state, subExs) {
        state.subExs = subExs
    },
    setSReq(state, sReq) {
        state.sReq = sReq
    },
    setSReports(state, sReports) {
        state.sReports = sReports
    },
    setSTotalReports(state, sTotalReports) {
        state.sTotalReports = sTotalReports
    },
};

const actions = {

    getProfile({getters, commit, dispatch}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getProfile', body: body})
            .then(
                response => {

                    commit('setProfile', response.result[0])

                    if (!getters.getProfile.Role || (Object.keys(getters.getProfile.Role).length === 0 && getters.getProfile.Role.constructor === Object)) {

                        if (router.currentRoute.name !== 'confirmInfo')
                            if (getters.getProfile.ProfileCompleted === true) {
                                dispatch("getSReq")
                            } else {
                                if (router.currentRoute.name !== 'personalInfo')
                                    router.replace({name: 'personalInfo'})
                            }
                    } else {
                        if (router.currentRoute.name !== 'requests')
                            router.replace({name: 'requests'})
                    }
                },
                error => {
                    if (error.status === 403) {
                        Vue.$cookies.set(store.getters.getBearer, null)
                        Vue.$cookies.set(store.getters.getACK, null)
                        if (router.currentRoute.path !== '/login')
                            router.replace({path: '/login'});
                    }
                })
    },

    setProfile({getters, dispatch}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/setProfile', body: body})
            .then(() => {

                dispatch('getProfile')

                if (router.currentRoute.name !== 'confirmInfo')
                    router.replace({name: 'confirmInfo'})

            })
    },

    uploadAvatar({getters}, pic) {

        const fd = new FormData();

        fd.append('avatar', pic, pic.name);

        this.dispatch('sendRequest', {url: 'Attachment/avatar/upload', body: fd})
            .then(response => {
                getters.getAdminProfile.Avatar = response.result[0].FileID
                Vue.toasted.success('بارگزاری عکس انجام شد!', {duration: '1000'})
            })
    },


    getMainStreets({getters, commit}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getPathWays', body: body})
            .then(response => {

                commit('setMainStreets', response.result)
            })
    },

    getSubStreets({getters, commit}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getSubWays', body: body})
            .then(response => {

                commit('setSubStreets', response.result)
            })
    },

    getMajorEx({getters, commit}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getMajorEx', body: body})
            .then(response => {

                commit('setMajorExs', response.result)
            })
    },

    getSubEx({getters, commit}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getSubEx', body: body})
            .then(response => {

                commit('setSubExs', response.result)
            })
    },

    getSReq({getters, commit}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/getSReq', body: body})
            .then((response) => {

                commit('setSReq', response.result[0])

                if (response.result.length === 0) {
                    if (router.currentRoute.name !== 'confirmInfo')
                        router.replace({name: 'confirmInfo'})
                } else {
                    if (response.result[0].Status == 0) {
                        if (router.currentRoute.name !== 'pay')
                            router.replace({name: 'pay'})
                    } else {
                        if (router.currentRoute.name !== 'receipt')
                            router.replace({name: 'receipt'})
                    }
                }
            })
    },

    setSReq({getters, dispatch}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/setSReq', body: body})
            .then(() => {

                router.replace({name: 'pay'})
                dispatch("getSReq")

            })
    },


    paySReq({getters, dispatch}) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Doctor/paySReq', body: body})
            .then(() => {

                router.replace({name: 'receipt'})
                dispatch("getSReq")
            })
    },

    getSRequests({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getPersonModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/getSRequests', body: body})
            .then((response) => {
                if (data.index === 0) {
                    commit("setSTotalReports", response.meta.TotalCount);
                }

                commit('setSReports', response.result)
            })
    },


};

export default {
    state,
    getters,
    mutations,
    actions
}
