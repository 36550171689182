import ReqJson from "@/resource/reqJson";
import {router} from "@/router/router";

const state = {
    requestsList: [],
    totalRequestsCount: 0,
    requestDetail: {Customer: {SupportOrgan: {}, IdDocuments: []}, ReserveRequest: {Market: {MarketType: {}, MarketCategory: {}}}},
    reserveRequest: {},

    requestLogsList: [],
    totalRequestLogsCount: 0,

    maxBoothCount: 0,
};

const getters = {
    getRequestsList(state) {
        return state.requestsList
    },
    getTotalRequestsCount(state) {
        return state.totalRequestsCount
    },
    getRequestDetail(state) {
        return state.requestDetail
    },
    getMaxBoothCount(state) {
        return state.maxBoothCount
    },
    getReserveRequest(state) {
        return state.reserveRequest
    },
    getRequestLogsList(state) {
        return state.requestLogsList
    },
    getTotalRequestLogsCount(state) {
        return state.totalRequestLogsCount
    },
};

const mutations = {
    setRequestsList(state, requestsList) {
        state.requestsList = requestsList
    },
    setTotalRequestsCount(state, totalRequestsCount) {
        state.totalRequestsCount = totalRequestsCount
    },
    setRequestDetail(state, requestDetail) {
        state.requestDetail = requestDetail
    },
    setMaxBoothCount(state, maxBoothCount) {
        state.maxBoothCount = maxBoothCount
    },
    setReserveRequest(state, reserveRequest) {
        state.reserveRequest = reserveRequest
    },
    setRequestRequestLogsList(state, requestLogsList) {
        state.requestLogsList = requestLogsList
    },
    setTotalRequestLogsCount(state, totalRequestLogsCount) {
        state.totalRequestLogsCount = totalRequestLogsCount
    },
};

const actions = {

    getRequests({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/request/reserveRequests', body: body})
            .then(response => {
                commit("setRequestsList", response.result);
                if (data.index === 0) {
                    commit("setTotalRequestsCount", response.meta.TotalCount);
                }
            })
    },

    getRequestDetails({getters, commit}, reserveRequestID) {

        commit("setRequestDetail", {Customer: {SupportOrgan: {}, IdDocuments: []}, ReserveRequest: {Market: {MarketType: {}, MarketCategory: {}}}});

        const object = {};
        object['ReserveRequest'] = {
            'ID': reserveRequestID
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/request/requestDetail', body: body})
            .then(response => {
                commit("setRequestDetail", response.result[0]);
                commit("setMaxBoothCount", response.result[0].ReserveRequest.BoothCount);
            })

    },

    setNewRequest({getters}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/request/newRequest', body: body})
            .then(() => {

                router.push('/market/marketRequests')
            })
    },

    confirmRequest({getters, dispatch}, data) {

        const mainObj = {
            'ReserveRequest': {
                'ID': data.requestID,
                'BoothCount': data.boothCount,
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/request/confirmRequest', body: body})
            .then(() => {


                const obj = {
                    filter: {
                        'Market': data.marketID,
                        'Search': data.searchTxt,
                    },
                    index: 0
                }

                dispatch('getRequests', obj)

                if (router.currentRoute.path.includes('/requestDetail'))
                    router.go(-1)

            })
    },

    denyRequest({getters, dispatch}, data) {

        const mainObj = {
            'ReserveRequest': {
                'ID': data.requestID
            },
            'Description': data.description
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/request/denyRequest', body: body})
            .then(() => {


                const obj = {
                    filter: {
                        'Market': data.marketID,
                        'Search': data.searchTxt,
                    },
                    index: 0
                }

                dispatch('getRequests', obj)

                if (router.currentRoute.path.includes('/requestDetail'))
                    router.go(-1)
            })
    },

    getRequestLogs({getters, commit, dispatch}, data) {
        return new Promise((resolve) => {

            if (data.index === 0) {
                commit('setRequestRequestLogsList', [])
                commit('setTotalRequestLogsCount', 0)
            }

            const mainObj = {
                ReserveRequest: {
                    ID: data.requestID
                }
            }

            const filter = {
                'Market': {
                    ID: data.marketID
                }
            }


            const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

            this.dispatch('sendRequest', {url: 'Admin/request/requestLogs', body: body})
                .then(response => {

                    if (data.index === 0)
                        commit("setTotalRequestLogsCount", response.meta.TotalCount);

                    if (response.result.length !== 0) {
                        for (let i = 0; i < response.result[0].RequestLogs.length; i++) {
                            getters.getRequestLogsList.push(response.result[0].RequestLogs[i])
                        }

                        if (response.result[0].RequestLogs.length === getters.getLimit) {
                            data.index = getters.getRequestLogsList.length
                            dispatch('getRequestLogs', data)
                        } else {
                            resolve(getters.getRequestLogsList)
                        }
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
