export const menu = {
    methods: {
        menuClick() {
            let menu = document.querySelector(".menu-container")
            let modalOverlay = document.querySelector(".menu-overlay")
            if (!menu.classList.contains('open-menu-container')) {
                menu.classList.add(['open-menu-container'])
                modalOverlay.classList = 'menu-overlay show-menu-overlay'
            } else {
                menu.classList.remove(['open-menu-container'])
                modalOverlay.classList = 'menu-overlay'
            }
        }
    }
}







