import ReqJson from "@/resource/reqJson";
import {router} from '@/router/router'
import Vue from 'vue';


const state = {
    mobile: '',
    nationalCode: '',
    securityCode: '',
    captcha: {},
};
const getters = {
    getMobile(state) {
        return state.mobile
    },
    getNationalCode(state) {
        return state.nationalCode
    },
    getSecurityCode(state) {
        return state.securityCode
    },
    getCaptcha(state) {
        return state.captcha
    },
};
const mutations = {
    setMobile(state, mobile) {
        state.mobile = mobile
    },
    setNationalCode(state, nationalCode) {
        state.nationalCode = nationalCode
    },
    setSecurityCode(state, securityCode) {
        state.securityCode = securityCode
    },
    setCaptcha(state, captcha) {
        state.captcha = captcha
    },
};
const actions = {

    captcha({getters, commit}) {

        const data = {}

        this.dispatch('sendRequestGet', {url: 'Login/captcha', body: new ReqJson(getters.getLoginModel, data, {}, 0, 0).reqJS()})
            .then(response => {
                commit('setCaptcha', response)
            })
    },

    signup({getters}) {

        return new Promise((resolve, reject) => {


                const data = {
                    "Mobile": getters.getMobile,
                    "NationalCode": getters.getNationalCode,
                    "OS": "Web",
                    "Platform": "WebApp",
                    "CTAG": getters.getCaptcha.Tag,
                    "CCODE": getters.getSecurityCode
                }

                const body = new ReqJson(getters.getLoginModel, data, {}, 0, 0).reqJS()

                this.dispatch('sendRequest', {url: 'Login/signup', body: body})
                    .then(
                        response => {
                            if (router.currentRoute.path !== '/login/enterVerificationCode')
                                router.push('login/enterVerificationCode')


                                Vue.toasted.error(response.result[0].Verify, {duration: '8000'})

                            // if (!getters.isHideLogs)
                            // console.log("----   " + response.result[0].Verify + "   ----")

                            resolve()
                        },
                        () => {
                            reject()
                        })

            },
        )

    },

    verify({getters, dispatch}, verifyCode) {

        return new Promise((resolve) => {

            const data = {
                "Mobile": getters.getMobile,
                "NationalCode": getters.getNationalCode,
                "Verify": verifyCode
            }

            this.dispatch('sendRequest', {url: 'Login/verify', body: new ReqJson(getters.getLoginModel, data, {}, 0, 0).reqJS()})
                .then(response => {

                    getters.isAdmin === true ? Vue.$cookies.set('status', 'admin', "7d") : Vue.$cookies.set('status', 'exhibitor', "7d")
                    Vue.$cookies.set(getters.getBearer, response.result[0].Bearer, "7d")
                    Vue.$cookies.set(getters.getACK, response.result[0].ACK, "7d")

                    dispatch('getProfile')

                    resolve()
                })
        })
    },


};

export default {
    state,
    getters,
    mutations,
    actions
}
