import ReqJson from "@/resource/reqJson";

const state = {
    financialList: [],
    totalFinancialListCount: 0,
};

const getters = {
    getFinancialList(state) {
        return state.financialList
    },
    getTotalFinancialListCount(state) {
        return state.totalFinancialListCount
    },
};

const mutations = {
    setFinancialList(state, financialList) {
        state.financialList = financialList
    },
    setTotalFinancialListCount(state, totalFinancialListCount) {
        state.totalFinancialListCount = totalFinancialListCount
    },
};

const actions = {

    getFinancialReport({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = data.filter


        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/finance/finReport', body: body})
            .then(response => {
                commit("setFinancialList", response.result);
                if (data.index === 0) {
                    commit("setTotalFinancialListCount", response.meta.TotalCount);
                }
            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
