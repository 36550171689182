<template>
  <div>

    <div v-if="$store.getters.isAdmin" class="login-info-header" style="padding-top: 10px">
      <h5>لطفا جهت ورود , همراه خود را وارد کنید</h5>
    </div>

    <div v-else class="login-info-header-customer">

      <div class="login-select-customer login-select-customer-enter" :class="{'active-customer-select' : customerSelect==='login'}" >
        <p>ورود به سامانه</p>
      </div>

      <div class="login-select-customer login-select-customer-sign-in" :class="{'active-customer-select' : customerSelect==='signup'}">
        <p>ثبت نام</p>
      </div>

    </div>

    <div class="login-info-content">
      <h4 v-if="$store.getters.isAdmin">ورود به حساب کاربری مدیر</h4>
      <h4 v-else-if="customerSelect === 'login'">ورود</h4>
      <h4 v-else-if="customerSelect === 'signup'">ثبت نام</h4>

      <div class="input-row width-250">
        <label for="mobile" class="label-input bg-white-label-input">شماره همراه</label>
        <input id="mobile" v-model="mobile" class="w-100 text-align-center" type="text" maxlength="11" v-on:keydown.enter="signup" :class="{'invalid-input':$v.mobile.$invalid && formInvalid}" @input="$v.mobile.$touch()" ref="mobileNumber">
      </div>
      <div class="invalid-container width-250">
        <p class="error-text" v-if="$v.mobile.$invalid && !$v.mobile.required && formInvalid">شماره همراه را وارد کنید</p>
        <p class="error-text" v-else-if="!$v.mobile.validMobileNumber && $v.mobile.$dirty && formInvalid">شماره همراه صحیح نیست</p>
      </div>

      <div class="input-row width-250" style="margin-top: 10px">
        <label for="ٔNC" class="label-input bg-white-label-input">کد ملی</label>
        <input id="ٔNC" v-model="nationalCode" class="w-100 text-align-center" type="text" maxlength="10" v-on:keydown.enter="signup" :class="{'invalid-input':$v.nationalCode.$invalid && formInvalid}" @input="$v.nationalCode.$touch()">
      </div>
      <div class="invalid-container width-250">
        <p class="error-text" v-if="$v.nationalCode.$invalid && !$v.nationalCode.required && formInvalid">کد ملی خود را وارد کنید</p>
        <p class="error-text" v-if="!$v.nationalCode.validNationalCode && $v.nationalCode.$dirty  && formInvalid">کد ملی صحیح نیست</p>
      </div>

      <div class="d-flex captcha-container">
        <input v-model="securityCode" class="text-align-center" type="text" style="width: 107px" v-on:keydown.enter="signup" :class="{'invalid-input':($v.securityCode.$invalid && formInvalid) || invalidSecurityCode}"
               @input="$v.securityCode.$touch()">
        <img v-if="$store.getters.getCaptcha.FileName" class="captcha-content" :src="$store.getters.getImageCaptchaURL + $store.getters.getCaptcha.FileName" alt="">
        <img class="captcha-refresh" src="../../assets/images/refresh.png" alt="" v-on:click="getCaptcha">
      </div>

      <div class="invalid-container width-250">
        <p class="error-text" v-if="$v.securityCode.$invalid && !$v.securityCode.required && formInvalid">کد امنیتی را وارد کنید</p>
        <p class="error-text" v-else-if="invalidSecurityCode">کد امنیتی صحیح نیست</p>
      </div>

      <button class="btn orange-bg width-250 btn-enter-phone" v-on:click="signup">دریافت کد ورود</button>

    </div>

  </div>
</template>

<script>

import {constMethods} from '@/mixin/constMethods'
import {required} from "vuelidate/lib/validators";
import {validation} from "@/mixin/validation";

export default {
  name: "enterPhoneNumber",
  data() {
    return {
      mobile: '',
      nationalCode: '',
      securityCode: '',
      formInvalid: false,
      invalidSecurityCode: false,
      customerSelect: 'signup'
    }
  },
  mixins: [
    constMethods,
    validation
  ],
  methods: {
    onSelectCustomer(status) {
      this.customerSelect = status
    },

    signup() {
      if (!this.$v.$invalid) {

        this.$store.commit('setMobile', this.mobile)
        this.$store.commit('setNationalCode', this.nationalCode)
        this.$store.commit('setSecurityCode', this.securityCode)

        this.$store.dispatch("signup").then(
            () => {
            },
            () => {
              // this.securityCode = ''
              this.invalidSecurityCode = true
              this.getCaptcha()
            }
        )
      } else {
        this.$refs.mobileNumber.focus()
        this.formInvalid = true
      }
    },
    getCaptcha() {
      this.$store.dispatch("captcha")
    }
  },
  validations: {
    mobile: {
      required,
      validMobileNumber: function (val) {
        if (val === "") return true

        let number = this.convertNumbersToEnglish(val)
        return this.validMobile(number);
      }
    },
    nationalCode: {
      required,
      validNationalCode: function (val) {
        if (val === undefined || val === "") {
          return true
        } else {
          let res = this.convertNumbersToEnglish(val)
          return this.validNC(res);
        }
      }
    },
    securityCode: {
      required,
    },
  },
  mounted() {
    this.$refs.mobileNumber.focus()
    this.getCaptcha()
  },
  watch: {
    securityCode: function () {
      this.invalidSecurityCode = false
    }
  }
}
</script>

<style scoped>

</style>