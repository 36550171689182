import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

import server from "@/store/modules/const/server";
import models from "@/store/modules/const/models"
import staticValues from "@/store/modules/const/static";
import modal from "@/store/modules/const/modal";
import login from "@/store/modules/login/login";
import market from "@/store/modules/admin/bazaar/market";
import booth from "@/store/modules/admin/bazaar/booth";
import guard from "@/store/modules/admin/bazaar/guard";
import manager from "@/store/modules/admin/bazaar/manager";
import request from "@/store/modules/admin/request/request";
import systemUsers from "@/store/modules/admin/systemUsers/systemUsers";
import report from "@/store/modules/admin/report/report";
import violation from "@/store/modules/admin/violation/violation";
import exhibitors from "@/store/modules/admin/exhibitors/exhibitors";
import contractors from "@/store/modules/admin/contractors/contractors";
import financial from "@/store/modules/admin/financial/financial";
import person from "@/store/modules/admin/person/person";
import profile from "@/store/modules/admin/profile/profile";
import adminDocuments from "@/store/modules/admin/settings/document/adminDocuments";
import adminOtherSettings from "@/store/modules/admin/settings/other/adminOtherSettings";
import customerDocuments from "@/store/modules/admin/settings/document/customerDocuments";
import adminMarketCategory from "@/store/modules/admin/settings/marketCategory/adminMarketCategory";
import adminOrgans from "@/store/modules/admin/settings/organs/adminOrgans";
import customerProfile from "@/store/modules/customer/profile/customerProfile";
import customerRequest from "@/store/modules/customer/request/customerRequest"
import customerMarket from "@/store/modules/customer/market/customerMarket";
import customerReserve from "@/store/modules/customer/reserve/customerReserve";
import customerBooth from "@/store/modules/customer/booth/customerBooth";
import customerFactor from "@/store/modules/customer/factor/customerFactor";
import customerReport from "@/store/modules/customer/report/customerReport";


export const store = new Vuex.Store({
    state: {},

    modules: {
        server,
        staticValues,
        models,
        modal,
        login,
        market,
        booth,
        guard,
        manager,
        request,
        systemUsers,
        report,
        violation,
        person,
        exhibitors,
        profile,
        financial,
        contractors,
        adminDocuments,
        customerDocuments,
        adminOrgans,
        adminMarketCategory,
        adminOtherSettings,
        customerProfile,
        customerRequest,
        customerMarket,
        customerReserve,
        customerBooth,
        customerFactor,
        customerReport,
    }

})
