<template>
  <div class="menu pt-50">

    <div class="text-align-center"><img src="../../../assets/images/fava_logo_250.png" alt="logo" class="menu-logo">
    </div>

    <h4 class="menu-title">سامانه ساماندهی تابلو پزشکان و مشاغل وابسته پزشکی</h4>

    <div class="profile-menu">
      <div v-if="profile.Avatar" class="header-person-img no-img"></div>
      <div class="header-person-img" v-else><img :src="$store.getters.getImageServerURL + profile.Avatar" alt="" class="w-100"></div>

      <div class="header-person-info" v-on:click="onClickProfile">
        <div class="header-person-info-name d-flex align-items-top">
          <p class="m-0 ellipsis text-white">{{ profile.Name }}</p>
          <span style="margin: 0 2px"></span>
          <p class="m-0 ellipsis text-white">{{ profile.Family }}</p>
        </div>
        <p v-if="profile.Role" class="header-person-info-position">{{ profile.Role.Title }}</p>
      </div>

    </div>

    <ul class="menu-items">

      <router-link class="d-flex align-items-center menu-item" to="/requests" active-class="menu-item-active" replace @click.native="menuClick">
        <img src="../../../assets/images/bazaar.png" alt="bazaar" class="menu-item-logo">
        <p class="menu-item-text">گزارش گیری</p>
      </router-link>

      <li class="d-flex align-items-center menu-item" v-on:click="logout">
        <img src="../../../assets/images/logout.png" alt="bazaar" class="menu-item-logo" style="opacity: 1">
        <p class="menu-item-text red-color" style="opacity: 1">خروج از سیستم</p>
      </li>

    </ul>

  </div>
</template>

<script>
import Vue from "vue";
import {menu} from "@/mixin/menu";

export default {
  name: "dashboardMenu",
  mixins: [
    menu
  ],
  methods: {
    onClickProfile() {
      if (this.$router.currentRoute.path === '/bazaar')
        if (this.$router.currentRoute.path !== '/profile')
          this.$router.push('/profile')
    },
    logout() {
      this.menuClick()
      this.$store.dispatch('setModal', {'title': 'آیا از خروج اطمینان دارید؟', 'type': 'warning', 'button': 2}).then(
          () => {
            Vue.$cookies.set(this.$store.getters.getBearer, null)
            Vue.$cookies.set(this.$store.getters.getACK, null)
            Vue.$cookies.set('status', null)
            if (this.$router.currentRoute.path !== '/login')
              this.$router.replace({path: '/login'});

            this.$store.commit('setAllMarketsList', [])
            this.$store.commit('setFilteredMarketsList', [])
          }
      )
    },
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    },
  },
}
</script>

<style scoped>

</style>