import Vue from 'vue'

export const EventBus = new Vue({
    methods: {
        clickOK() {
            this.$emit('clickOnOK')
        },
        clickYes() {
            this.$emit('clickOnYes')
        },
        clickNo() {
            this.$emit('clickOnNo')
        },
    },
    data() {
        return {}
    },
    created() {}
});