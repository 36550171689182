import ReqJson from "@/resource/reqJson";
import Vue from "vue";

const state = {
    customerMarkets: [],
    totalCustomerMarketsCount: 0,

    allCustomerMarkets: [],
    allTotalCustomerMarketsCount: 0,

    customerMarketTypes: [],
    customerMarketCategories: [],
};

const getters = {
    getCustomerMarkets(state) {
        return state.customerMarkets
    },
    getTotalCustomerMarketsCount(state) {
        return state.totalCustomerMarketsCount
    },
    getAllCustomerMarkets(state) {
        return state.allCustomerMarkets
    },
    getAllTotalCustomerMarketsCount(state) {
        return state.allTotalCustomerMarketsCount
    },
    getCustomerMarketTypes(state) {
        return state.customerMarketTypes
    },
    getCustomerMarketCategories(state) {
        return state.customerMarketCategories
    },
};

const mutations = {
    setCustomerMarkets(state, customerMarkets) {
        state.customerMarkets = customerMarkets
    },
    setTotalCustomerMarketsCount(state, totalCustomerMarketsCount) {
        state.totalCustomerMarketsCount = totalCustomerMarketsCount
    },
    setAllCustomerMarkets(state, allCustomerMarkets) {
        state.allCustomerMarkets = allCustomerMarkets
    },
    setAllTotalCustomerMarketsCount(state, allTotalCustomerMarketsCount) {
        state.allTotalCustomerMarketsCount = allTotalCustomerMarketsCount
    },
    setCustomerMarketTypes(state, customerMarketTypes) {
        state.customerMarketTypes = customerMarketTypes
    },
    setCustomerMarketCategories(state, customerMarketCategories) {
        state.customerMarketCategories = customerMarketCategories
    },
};

const actions = {

    getCustomerMarkets({getters, commit, dispatch}, data) {

        if (data.index === 0)
            commit('setCustomerMarkets', [])

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/getMarkets', body: body})
            .then(response => {

                if (data.index === 0)
                    commit("setTotalCustomerMarketsCount", response.meta.TotalCount);

                for (let i = 0; i < response.result.length; i++) {
                    getters.getCustomerMarkets.push(response.result[i])
                }

                if (response.result.length === getters.getLimit) {
                    data.index = getters.getCustomerMarkets.length
                    dispatch('getCustomerMarkets', data)
                } else {
                    if (getters.getCustomerMarkets.length === 0)
                        Vue.toasted.error('بازاری با مشخصات وارد شده در سامانه وجود ندارد!', {duration: '2000'})
                }

            })
    },

    getAllCustomerMarkets({getters, commit, dispatch}, index) {

        if (index === 0)
            commit('setAllCustomerMarkets', [])

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/getMarkets', body: body})
            .then(response => {

                if (index === 0)
                    commit("setAllTotalCustomerMarketsCount", response.meta.TotalCount);

                for (let i = 0; i < response.result.length; i++) {
                    getters.getAllCustomerMarkets.push(response.result[i])
                }

                if (response.result.length === getters.getLimit) {
                    index = getters.getAllCustomerMarkets.length
                    dispatch('getAllCustomerMarkets', index)
                }

            })
    },

    getCustomerMarketTypes(context) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(context.rootGetters.getCustomerModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/marketTypes', body: body})
            .then(response => {
                context.commit("setCustomerMarketTypes", response.result);
            })
    },

    getCustomerMarketCategories(context) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(context.rootGetters.getCustomerModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/marketCategories', body: body})
            .then(response => {
                context.commit("setCustomerMarketCategories", response.result);
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
