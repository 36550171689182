import {EventBus} from "@/components/helper/modal/popupModal/EventBus/EventBus";


const state = {
    modalShow: false,
    modalTitle: '',
    modalButton: 0,
    modalType: '',
    yesBtn : 'بله',
    noBtn : 'خیر',
    okBtn : 'خب',
};

const getters = {
    isModalShow(state) {
        return state.modalShow
    },
    getModalTitle(state) {
        return state.modalTitle
    },
    getModalButton(state) {
        return state.modalButton
    },
    getModalType(state) {
        return state.modalType
    },
    getModalYesBtn(state) {
        return state.yesBtn
    },
    getModalNoBtn(state) {
        return state.noBtn
    },
    getModalOkBtn(state) {
        return state.okBtn
    },
};

const mutations = {
    setModalShow(state, modalShow) {
        state.modalShow = modalShow
    },
    setModalTitle(state, modalTitle) {
        state.modalTitle = modalTitle
    },
    setModalButton(state, modalButton) {
        state.modalButton = modalButton === undefined ? 1 : modalButton
    },
    setModalType(state, modalType) {
        state.modalType = modalType === undefined ? 'success' : modalType
    },
    setModalYesBtn(state, yesBtn) {
        state.yesBtn = yesBtn  === undefined ? 'بله' : yesBtn
    },
    setModalNoBtn(state, noBtn) {
        state.noBtn = noBtn  === undefined ? 'خیر' : noBtn
    },
    setModalOkBtn(state, okBtn) {
        state.okBtn = okBtn  === undefined ? 'خب' : okBtn
    },
};

const actions = {

    setModal({commit}, data) {
        return new Promise((resolve, reject) => {

            commit('setModalShow', true)
            commit('setModalTitle', data.title)
            commit('setModalButton', data.button)
            commit('setModalType', data.type)
            commit('setModalOkBtn', data.ok)
            commit('setModalYesBtn', data.yes)
            commit('setModalNoBtn', data.no)

            EventBus.$on('clickOnOK', () => {
                resolve()
            })

            EventBus.$on('clickOnYes', () => {
                resolve()
            })

            EventBus.$on('clickOnNo', () => {
                reject()
            })
        })
    }

};

export default {
    state,
    getters,
    mutations,
    actions
}