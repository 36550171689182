import ReqJson from "@/resource/reqJson";

const state = {
    adminDocumentList: [],
    totalAdminDocumentListCount: 0,
};

const getters = {
    getAdminDocumentList(state) {
        return state.adminDocumentList
    },
    getTotalAdminDocumentCount(state) {
        return state.totaladminDocumentListCount
    },
};

const mutations = {
    setAdminDocumentList(state, adminDocumentList) {
        state.adminDocumentList = adminDocumentList
    },
    setTotalAdminDocumentCount(state, totalAdminDocumentListCount) {
        state.totaladminDocumentListCount = totalAdminDocumentListCount
    },
};

const actions = {

    getAdminDocuments({getters, commit, dispatch}, index) {

        if (index === 0)
            commit('setAdminDocumentList', [])

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/mDocumentList', body: body})
            .then(response => {

                if (response.result.length > 0) {

                    if (index === 0)
                        commit("setTotalAdminDocumentCount", response.meta.TotalCount);

                    for (let i = 0; i < response.result.length; i++) {
                        getters.getAdminDocumentList.push(response.result[i])
                    }

                    if (response.result.length === getters.getLimit) {
                        index = getters.getAdminDocumentList.length
                        dispatch('getAdminDocuments', index)
                    }
                }
            })
    },

    addAdminDocument({getters}, item) {

        const mainObj = {
            'IdDocument': {
                'ID': item.ID,
                'Title': item.Title,
                'Description': '1'
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/addMDocument', body: body})
            .then((response) => {
                if (item.ID) {
                    for (let i = 0; i < getters.getAdminDocumentList.length; i++) {
                        if (getters.getAdminDocumentList[i].ID === item.ID) {
                            getters.getAdminDocumentList[i] = response.result[0]
                            break
                        }
                    }
                } else {
                    getters.getAdminDocumentList.push(response.result[0])
                }
            })
    },


    switchAdminDocumentStatus({getters}, item) {
        const object = {};
        object['IdDocument'] = {
            "ID": item.ID,
            "Active": item.Active == 1 ? 0 : 1
        }

        const mainObj = object
        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/switchDocStatus', body: body})
            .then(() => {

                for (let i = 0; i < getters.getAdminDocumentList.length; i++) {
                    if (getters.getAdminDocumentList[i].ID === item.ID) {
                        getters.getAdminDocumentList[i].Active = getters.getAdminDocumentList[i].Active == 1 ? 0 : 1
                        break
                    }
                }
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
