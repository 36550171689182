<template>

  <div>
    <transition name="fade" appear>
      <div class="menu-overlay" v-on:click="menuClick"></div>
    </transition>

    <div class="menu-container">
      <dashboard-menu></dashboard-menu>
    </div>

    <div class="dashboard-container">
      <dashboard-content></dashboard-content>
    </div>
  </div>

</template>

<script>

import dashboardContent from "@/components/dashboard/sections/content/dashboardContent";
import dashboardMenu from "@/components/dashboard/sections/dashboardMenu";
import {menu} from "@/mixin/menu";

export default {
  name: "mainDashboard",
  components: {
    'dashboard-content': dashboardContent,
    'dashboard-menu': dashboardMenu
  },
  mixins: [
    menu
  ]
}
</script>

<style scoped>

</style>