<template>
  <div>

    <div class="login-info-header" style="padding-top: 10px">
      <h5>کد اعتبار سنجی به همراه شما ارسال شد</h5>
      <h5>لطفا کد را در کادر زیر وارد کنید</h5>
    </div>

    <div class="login-info-content">

      <div class="relative width-250">
        <div class="d-flex form-row-enter-code" style="direction: ltr">

          <div v-for="(digit, index) in digits" :key="digit.id">
            <input
                v-bind:class="{'invalid-input' : incompleteCode}"
                class="input-enter-code"
                type="text"
                v-model="digit.value"
                maxlength="1"
                ref="digits"
                @input="moveToNextField($event.target.value, index)"
                v-on:keydown.enter="verify">
          </div>
        </div>

      </div>

      <div class="invalid-container width-250">
        <p v-if="incompleteCode" style="margin-right: 8px" class="error-text">کد ورود را وارد کنید</p>
      </div>


      <div class="timer">
        <p id="time"></p>
      </div>

      <button class="btn orange-bg width-250 btn-enter-phone" v-on:click="verify">ورود به حساب</button>

      <div class="login-info-content-footer width-250">
        <p>کد را دریافت نکردید؟</p>
        <p :class="{'disabled' : !endOfTimer}" v-on:click="reSendCode">ارسال مجدد کد</p>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "enterVerificationCode",
  data() {
    return {
      endOfTimer: false,
      digits: [
        {id: "1", value: ""},
        {id: "2", value: ""},
        {id: "3", value: ""},
        {id: "4", value: ""},
        {id: "5", value: ""},
      ],
      incompleteCode: false,
      timerInterval: null
    }
  },
  methods: {

    startTimer() {
      const self = this;
      let duration = 120 - 1
      let timer = duration, minutes, seconds;

      document.querySelector('#time').textContent = '02' + ":" + '00'

      this.timerInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (--timer < 0) {
          self.endOfTimer = true
        } else {
          self.endOfTimer = false
        }
        document.querySelector('#time').textContent = minutes + ":" + seconds

      }, 1000);
    },

    reSendCode() {
      if (this.endOfTimer) {
        this.endOfTimer = false
        this.$refs.digits[0].focus()
        this.$store.dispatch("signup").then(
            () => {
              this.startTimer()
            })
      }
    },

    moveToNextField(value, index) {
      const nextIndex = index + 1
      if (nextIndex < this.digits.length && value.length >= 1) {
        this.$refs.digits[nextIndex].focus()
      }

      this.incompleteCode = false
    },

    createVerificationCode() {
      this.vCode = ''
      for (let i = 0; i < this.digits.length; i++)
        this.vCode += this.digits[i].value

      return this.vCode
    },

    validation() {
      for (let i = 0; i < this.digits.length; i++)
        if (this.digits[i].value === "") return false
      return true
    },

    verify() {
      if (this.validation()) {
        this.$store.dispatch("verify", this.createVerificationCode()).then(
            () => {
              clearInterval(this.timerInterval)
            })
      } else {
        this.$refs.digits[0].focus()
        this.incompleteCode = true
      }
    },
  },
  mounted() {
    this.$refs.digits[0].focus()
    this.startTimer()
  },
  watch: {
    endOfTimer() {
      if (this.endOfTimer) clearInterval(this.timerInterval)
    }
  }
}
</script>

<style scoped>

</style>