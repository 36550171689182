import Vue from 'vue'
import VueResource from 'vue-resource'
import {store} from '@/store/store'
import {router} from '@/router/router'
import {constMethods} from '@/mixin/constMethods'


Vue.use(VueResource)

Vue.http.options.root = store.getters.getServerURL

Vue.http.interceptors.push((request, next) => {
    request.headers.set('Authorization', 'Bearer ' + Vue.$cookies.get(store.getters.getBearer));

    if (request.method === 'POST') {
        if (!request.url.includes('Attachment')) {
            request.body = JSON.parse(constMethods.methods.convertNumbersToEnglish(JSON.stringify(request.body)))
        }
    }

    if (store.getters.isDebug)
        request.url = request.url.includes('?') ? request.url + '&' + store.getters.getDebugURL : request.url + '?' + store.getters.getDebugURL

    if (!store.getters.isHideLogs)
        console.log(request);//request sent to sever

    next(response => {//response received from server
        if (!store.getters.isHideLogs)
            console.log(response)

        if (response.status !== 200) {

            if (response.status === 401) {
                store.dispatch('setModal', {'title': 'دسترسی شما مجاز نیست!', 'type': 'error', 'button': 1}).then(
                    () => {
                        Vue.$cookies.set(store.getters.getBearer, null)
                        Vue.$cookies.set(store.getters.getACK, null)
                        if (router.currentRoute.path !== '/login')
                            router.replace({path: '/login'});
                    }
                )
            } else if (response.status === 404) {
                store.dispatch('setModal', {'title': 'خطا ۴۰۴!' + '\n' + response.url, 'type': 'error', 'button': 1})
            } else if (response.status === 500) {
                store.dispatch('setModal', {'title': 'خطا ۵۰۰!' + '\n' + response.url, 'type': 'error', 'button': 1})
            } else if (response.status === 0) {
                store.dispatch('setModal', {'title': 'ارتباط با سرور برقرار نیست!', 'type': 'error', 'button': 1})
            } else if (response.status !== 470 && response.body !== undefined && response.body !== null && response.body.message !== undefined && response.body.message !== null &&
                response.body.message.umsg !== undefined && response.body.message.umsg !== null) {
                store.dispatch('setModal', {'title': response.body.message.umsg, 'type': 'error', 'button': 1})
            }

        }

    })

});