import ReqJson from "@/resource/reqJson";
import Vue from "vue";
import {router} from "@/router/router";

const state = {
    usersList: [],
    selectedUser: {},
    totalUsersCount: 0,
    roles: []
};

const getters = {
    getUsersList(state) {
        return state.usersList
    },
    getSelectedUser(state) {
        return state.selectedUser
    },
    getTotalUsersCount(state) {
        return state.totalUsersCount
    },
    getRoles(state) {
        return state.roles
    },
};

const mutations = {
    setUsersList(state, usersList) {
        state.usersList = usersList
    },
    setSelectedUser(state, selectedUser) {
        state.selectedUser = selectedUser
    },
    setTotalUsersCount(state, totalUsersCount) {
        state.totalUsersCount = totalUsersCount
    },
    setRoles(state, roles) {
        state.roles = roles
    },
};

const actions = {

    getUsers({getters, commit}, data) {

        if (data.index === 0) {
            commit("setUsersList", []);
        }

        const mainObj = {
            "_": ''
        }

        const filter = {'MarketID': data.ID}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/userList', body: body})
            .then(response => {
                commit("setUsersList", response.result);
                if (response.meta.TotalCount) {
                    commit("setTotalUsersCount", response.meta.TotalCount);
                }
            })
    },

    addUser({getters}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/addUser', body: body})
            .then(() => {

                Vue.toasted.success('ثبت کاربر موفقیت آمیز', { /* some option */})

                router.go(-1)

            })
    },

    removeUser({getters, dispatch}, data) {

        const mainObj = {};
        mainObj['SystemUser'] = {
            "ID": data.SystemUser.ID,
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/removeUser', body: body})
            .then(() => {
                dispatch('getUsers', data.data)
            })
    },

    getRoles({getters, commit}, data) {

        const mainObj = {
            "Market": {
                "ID": data.marketID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/getRoles', body: body})
            .then(response => {
                commit("setRoles", response.result);
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
