import ReqJson from "@/resource/reqJson";

const state = {
    customerFactor: {Market:{},Booth:{},Customer:{},Reservation:{}},
    payLink: {}
};

const getters = {
    getCustomerFactor(state) {
        return state.customerFactor
    },
    getPayLink(state) {
        return state.payLink
    },
};

const mutations = {
    setCustomerFactor(state, customerFactor) {
        state.customerFactor = customerFactor
    },
    setPayLink(state, payLink) {
        state.payLink = payLink
    },
};

const actions = {

    getInvoiceList({getters, commit}, reserveID) {

        const mainObj = {
            "Reservation": {
                "ID": reserveID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/invoiceList', body: body})
            .then(response => {
                commit('setCustomerFactor', response.result[0])
            })
    },


    getPayLink({getters, commit}, invoiceID) {

       return new Promise((resolve) => {

            const mainObj = {
                "Invoice": {
                    "ID": invoiceID
                }
            }

            const filter = {}

            const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

            this.dispatch('sendRequest', {url: 'Pay/getPayLink', body: body})
                .then(response => {
                    commit('setPayLink', response.result[0])
                    resolve()
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
