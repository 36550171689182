<template>
  <div>
    <div class="register-header">
      <p>نتیجه ثبت درخواست شما</p>
      <p style="cursor: pointer" v-on:click="logout">خروج</p>
    </div>

    <div style="padding-top: 150px;">
      <div class="customer-factor-container">

        <div class="customer-factor-header">
          <p>رسید پرداخت</p>
        </div>

        <div class="customer-factor-body list-info-container">


          <div v-for="(item,index) in sReq.ChargeItems" v-bind:key="index">
            <div class="d-flex justify-content-between">
              <p>{{ item.Description }}:</p>
              <p>{{ thousandSeparator(item.Price) }} ریال</p>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <p>هزینه ساخت تابلو:</p>
            <p>{{ thousandSeparator(sReq.TotalPrice) }} ریال</p>
          </div>

          <div class="d-flex justify-content-between">
            <p>وضعیت پرداخت:</p>

            <p v-if="sReq.Status == 1">{{ "پرداخت شده" }}</p>
            <p v-else-if="sReq.Status == 0">{{ "در انتظار پرداخت" }}</p>
          </div>

        </div>
      </div>

      <div class="customer-factor-container notice-container" style="margin-top: 20px">
        <div class="d-flex justify-content-between">
          <p class="text-align-right">پرداخت شما با موفقیت ثبت شد</p>
          <p class="text-align-right">کد پیگیری: {{ sReq.TrackingCode }}</p>

        </div>

      </div>

    </div>
    <p>{{ sReq.Status }}</p>
  </div>
</template>

<script>
import Vue from "vue";
import {store} from "@/store/store";
import {router} from "@/router/router";
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "receipt",
  mixins: [
    constMethods
  ],
  methods: {
    logout() {
      Vue.$cookies.set(store.getters.getBearer, null)
      Vue.$cookies.set(store.getters.getACK, null)
      Vue.$cookies.set('status', null)
      if (router.currentRoute.path !== '/login')
        router.replace({path: '/login'});
    }
  },
  computed: {
    sReq() {
      return this.$store.getters.getSReq
    },
  }
}
</script>

<style scoped>

</style>