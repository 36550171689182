<template>
  <div class="login-bg">

    <div class="login-menu" v-if="!$router.currentRoute.path.includes('enterVerificationCode')">

      <div class="login-menu-item">
        <h5 class="text-white">خانه</h5>
        <div :class="{'login-active-line' : admin}"></div>
      </div>

      <div class="login-menu-item" v-on:click="$store.commit('setAdmin',true)">
        <h5 class="text-white">ورود و ثبت نام</h5>
        <div :class="{'login-active-line' : !admin}"></div>
      </div>

    </div>

    <div class="login-menu" v-else>

    </div>

    <div class="login-container">

      <transition enter-active-class="animate__animated animate__fadeIn">
        <router-view></router-view>
      </transition>

      <p class="login-footer">با ورود شماره همراه خود میتوانید وارد پنل مدیریت سامانه ساماندهی تابلو پزشکان شوید</p>

    </div>

    <div class="login-main-footer">
      <img src="../../assets/images/shahrdari_logo.png" alt="">
      </div>

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {}
  },
  computed: {
    admin() {
      return this.$store.getters.isAdmin
    }
  }
}
</script>

<style scoped>

</style>