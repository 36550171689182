<template>
  <div>

    <transition name="fade">
      <div v-show="show" class="base-modal-mask" v-on:click="onClickMask" @keyup.esc="onEsc"/>
    </transition>

    <transition :name="`${animation}`">
      <div class="base-modal-container" :style="{'width' : width + 'px','height' : height + 'px'}" v-show="show">
        <slot></slot>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: "baseModal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    animation: {
      type: String,
      default: 'fade'
    },
    closeOnClickMask: {
      type: Boolean,
      default: false
    },
    closeOnEsc: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
  },
  methods: {
    onEsc() {
      this.$emit('clickEsc');
      if (this.show && this.closeOnEsc)
        this.$emit('hide');
    },
    onClickMask() {
      this.$emit('clickMask');
      if (this.closeOnClickMask)
        this.$emit('hide');
    }
  }
}
</script>

<style scoped>

@import "./style/modalStyle.css";

@import "./style/animation/fade.css";
@import "./style/animation/zoom.css";
@import "./style/animation/door.css";
@import "./style/animation/flip.css";
@import "./style/animation/rotate.css";
@import "./style/animation/slide-up.css";
@import "./style/animation/slide-down.css";
@import "./style/animation/slide-left.css";
@import "./style/animation/slide-right.css";

</style>