<template>
  <div>
    <template>
      <paginate
          v-model="currentPage"
          :page-count="pages"
          :page-range="3"
          :margin-pages="1"
          :prev-text="'<'"
          :next-text="'>'"
          :break-view-text="'...'"
          :click-handler=onPageNumberClick
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-item-link'"
          :break-view-class="'break-view-class'"
          :active-class="'active-page'"
          :disabled-class="'disabled-class'"
          :first-last-button="true"
          :first-button-text="'<<'"
          :last-button-text="'>>'"
          :hide-prev-next="true"
          @input="asd">
      </paginate>
    </template>
  </div>
</template>

<script>
export default {
  name: "paginating",
  props: {
    totalCount: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      currentPage: 0
    }
  },
  methods: {
    asd(n) {
      n
      // console.log(n)
    },
    onPageNumberClick(e) {
      e
      // console.log('onPageNumberClick!!!')
      // let index = (e - 1) * this.$store.getters.getLimit;
      // this.$emit('onPageClick', index)
    }
  },
  computed: {
    pages: function () {
      return Math.ceil(this.totalCount / this.$store.getters.getLimit);
    },
  },
  watch: {
    totalCount: function () {
      this.currentPage = 1
      // if (this.pages > this.currentPage)
      //   this.currentPage++
      // else if (this.pages < this.currentPage)
      //   this.currentPage--
    },
    currentPage: function (n) {
      this.$emit('onPageClick', (n - 1) * this.$store.getters.getLimit)
    }
  }
}
</script>

<style>

@import "style/paginatingStyle.css";


</style>