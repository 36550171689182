import ReqJson from "@/resource/reqJson";
import {router} from "@/router/router";
import Vue from "vue";

const state = {
    showManageDocs: false,
    mangerDocumentList: [],
    mangerUploadItems: []
};

const getters = {
    isShowManagerDocs(state) {
        return state.showManageDocs
    },
    getMangerDocumentList(state) {
        return state.mangerDocumentList
    },
    getMangerUploadItems(state) {
        return state.mangerUploadItems
    },
};

const mutations = {
    setShowMangerDocs(state, showManageDocs) {
        state.showManageDocs = showManageDocs
    },
    setMangerDocumentList(state, mangerDocumentList) {
        state.mangerDocumentList = mangerDocumentList
    },
    setMangerUploadItems(state, mangerUploadItems) {
        state.mangerUploadItems = mangerUploadItems
    },
};

const actions = {

    assignManager({getters}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/assignManager', body: body})
            .then(() => {
                router.go(-4)
            })
    },

    getManagerIdDocumentList({getters, commit}) {

        commit('setMangerUploadItems', [])
        commit("setMangerDocumentList", [])

        const mainObj = {
            "IdDocument": {}
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/mDocumentList', body: body})
            .then(response => {
                commit('setMangerDocumentList', response.result)

                for (let i = 0; i < getters.getMangerDocumentList.length; i++) {
                    getters.getMangerUploadItems.push({url: '', file: null, isUploaded: false})
                    if (getters.getSelectedMarket.Manager.ManagerDocs)
                        for (let j = 0; j < getters.getSelectedMarket.Manager.ManagerDocs.length; j++) {
                            if (getters.getSelectedMarket.Manager.ManagerDocs[j].ID === getters.getMangerDocumentList[i].ID) {
                                getters.getMangerUploadItems[i].url = getters.getSelectedMarket.Manager.ManagerDocs[j].Attachment
                                getters.getMangerUploadItems[i].isUploaded = true
                            }
                        }
                }

                commit('setShowMangerDocs', true)

            })

    },

    uploadManagerDocs({getters}, data) {

        const fd = new FormData();
        fd.append(data.uploadDocID, data.pic, data.pic.name);


        // this.dispatch('sendRequest', {url: 'Attachment/manager_doc/upload?market=' + getters.getSelectedMarket.ID, body: fd})
        this.dispatch('sendRequest', {url: 'Attachment/id_docs/upload?ownerID=' + getters.getSelectedMarket.Manager.ID, body: fd})
            .then(response => {

                getters.getMangerUploadItems[data.index].url = response.result
                getters.getMangerUploadItems[data.index].isUploaded = true
                // dispatch('getCustomerProfile')
                Vue.toasted.success('بارگزاری مدرک انجام شد!', {duration: '1000'})
            })
    },


    fastUser({getters, dispatch}, mobile) {

        const mainObj = {
            "User": {
                'Mobile': mobile
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/fastUser', body: body})
            .then(() => {
                dispatch('searchCustomer', mobile)
            })
    },
};


export default {
    state,
    getters,
    mutations,
    actions
}
