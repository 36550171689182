import ReqJson from "@/resource/reqJson";
import Vue from 'vue'
import {router} from "@/router/router";

const state = {
    contractors: [],
    allContractors: [],
    totalContractorsCount: 0,
    selectedContractor: {}
};

const getters = {
    getContractorsList(state) {
        return state.contractors
    },
    getAllContractorsList(state) {
        return state.allContractors
    },
    getTotalContractorsCount(state) {
        return state.totalContractorsCount
    },
    getSelectedContractor(state) {
        return state.selectedContractor
    },

};

const mutations = {
    setContractorsList(state, contractors) {
        state.contractors = contractors
    },
    setAllContractorsList(state, allContractors) {
        state.allContractors = allContractors
    },
    setTotalContractorsCount(state, totalContractorsCount) {
        state.totalContractorsCount = totalContractorsCount
    },
    setSelectedContractor(state, selectedContractor) {
        state.selectedContractor = selectedContractor
    },
};

const actions = {

    getContractors({getters, commit}, index) {

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/projectManagers/getProjectManagers', body: body})
            .then(response => {
                commit("setContractorsList", response.result);
                if (index === 0)
                    commit("setTotalContractorsCount", response.meta.TotalCount);
            })
    },

    getAllContractors({getters, commit, dispatch}, index) {

        if (index === 0)
            commit('setAllContractorsList', [])

        const mainObj = {
            "_": ''
        }

        const filter = {
            Active : 1
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/projectManagers/getProjectManagers', body: body})
            .then(response => {

                if (response.result.length > 0) {

                    for (let i = 0; i < response.result.length; i++)
                        getters.getAllContractorsList.push(response.result[i])

                    if (response.result.length === getters.getLimit) {
                        index = getters.getAllContractorsList.length
                        dispatch('getAllContractors', index)
                    }
                }
            })
    },

    addContractor({getters}, data) {

        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/projectManagers/setProjectManager', body: body})
            .then(() => {


                if (data.ProjectManager.ID === undefined) {
                    Vue.toasted.success('ثبت پیمانکار موفقیت آمیز', { /* some option */})
                } else {
                    Vue.toasted.success('ویرایش پیمانکار موفقیت آمیز', { /* some option */})
                }

                router.go(-1)

            })
    },

    switchContractorStatus({getters}, contractor) {

        const object = {};
        object['ProjectManager'] = {
            'ID': contractor.ID,
            "Active": contractor.Active == 1 ? 0 : 1
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/projectManagers/switchPM', body: body})
            .then(() => {

                for (let i = 0; i < getters.getContractorsList.length; i++) {
                    if (getters.getContractorsList[i].ID === contractor.ID) {
                        getters.getContractorsList[i].Active = getters.getContractorsList[i].Active == 1 ? 0 : 1
                        break
                    }
                }

            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
