import ReqJson from "@/resource/reqJson";

const state = {
    exhibitorsList: [],
    totalExhibitorsCount: 0,
    selectedExhibitor: {}
};

const getters = {
    getExhibitorsList(state) {
        return state.exhibitorsList
    },
    getTotalExhibitorsCount(state) {
        return state.totalExhibitorsCount
    },
    getSelectedExhibitor(state) {
        return state.selectedExhibitor
    },
};

const mutations = {
    setExhibitorsList(state, exhibitorsList) {
        state.exhibitorsList = exhibitorsList
    },
    setTotalExhibitorsCount(state, totalExhibitorsCount) {
        state.totalExhibitorsCount = totalExhibitorsCount
    },
    setSelectedExhibitor(state, selectedExhibitor) {
        state.selectedExhibitor = selectedExhibitor
    },
};

const actions = {

    getExhibitors({getters, commit}, data) {

        const mainObj = {};
        mainObj['Market'] = {
            "ID": data.ID,
        }

        const filter = {
            CustomerSearch: data.search
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/market/exhibitorsList', body: body})
            .then(response => {
                commit("setExhibitorsList", response.result);
                if (data.index === 0) {
                    commit("setTotalExhibitorsCount", response.meta.TotalCount);
                }
            })
    },

    switchCustomerStatus({getters}, obj) {

        const mainObj = {};
        mainObj['Reservation'] = {
            "ID": obj.reserve.ID,
            "Status": obj.reserve.Active == 1 ? 0 : 1,
            "LastStatus": obj.title
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/reserve/switchReserveStatus', body: body})
            .then((response) => {

                for (let i = 0; i < getters.getExhibitorsList.length; i++) {
                    if (getters.getExhibitorsList[i].Reservation.ID === obj.reserve.ID) {
                        // getters.getExhibitorsList[i].Reservation.Active = getters.getExhibitorsList[i].Reservation.Active == 1 ? 0 : 1
                        getters.getExhibitorsList[i].Reservation = response.result[0]
                        break
                    }
                }

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
