import ReqJson from "@/resource/reqJson";
import Vue from "vue";

const state = {
    guardsList: [],
    totalGuardsCount: 0,
    guard: {}
};

const getters = {
    getGuardsList(state) {
        return state.guardsList
    },
    getTotalGuardsCount(state) {
        return state.totalGuardsCount
    },
    getGuard(state) {
        return state.guard
    },
};

const mutations = {
    setGuardsList(state, guardsList) {
        state.guardsList = guardsList
    },
    setTotalGuardsCount(state, totalGuardsCount) {
        state.totalGuardsCount = totalGuardsCount
    },
    setGuard(state, guard) {
        state.guard = guard
    },
};

const actions = {

    getGuards({getters, commit}, index) {

        if (index === 0) {
            commit("setGuardsList", []);
        }

        const object = {};
        object['Market'] = {
            'ID': getters.getSelectedMarket.ID
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/guard/guardsList', body: body})
            .then(response => {

                commit("setGuardsList", response.result);

                if (index === 0) {
                    commit("setTotalGuardsCount", response.meta.TotalCount);
                }
            })
    },

    setGuard({getters, dispatch}, data) {
        const mainObj = data

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/guard/addGuard' , body: body})
            .then(() => {
                dispatch("getGuards", 0)
                Vue.toasted.success('ثبت نگهبان انجام شد!', {duration: '1000'})

            })
    },

    searchGuard({getters, commit}, mobile) {

        const mainObj = {
            "_": ''
        }

        const filter = {
            'Mobile': mobile
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/guard/searchGuard', body: body})
            .then(response => {

                if (response.result.length !== 0)
                    commit('setGuard', response.result[0])
                else
                    commit('setGuard', {'PersonMobile': mobile})
            })
    },


    deleteGuard({getters, dispatch}, guardID) {

        const mainObj = {
            'Market': {
                'ID': getters.getSelectedMarket.ID
            },
            'SystemUser': {
                'ID': guardID
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/guard/delGuard', body: body})
            .then(() => {
                dispatch('getGuards', 0)
                Vue.toasted.success('حذف نگهبان انجام شد!', {duration: '1000'})
            })
    },

};


export default {
    state,
    getters,
    mutations,
    actions
}
