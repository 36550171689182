<template>
  <div>

<!--    <div class="search-report d-flex align-items-center" style="margin-top: 12px;">-->

<!--      <div class="d-flex align-items-center">-->

<!--        <div class="d-flex align-items-center">-->
<!--          <label for="name" class="label-header">نام یا همراه پزشک</label>-->
<!--          <input id="name" v-model="searchTxtDoc" class="mr-5 width-160">-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="d-flex align-items-center">-->

<!--        <div style="margin-right: 40px;" class="d-flex align-items-center">-->
<!--          <label for="startDateInput" class="label-header">از تاریخ</label>-->
<!--          <input id="startDateInput" class="width-90 mr-5 text-align-center" v-model="startDate"/>-->
<!--        </div>-->

<!--        <div style="margin-right: 20px;" class="d-flex align-items-center">-->
<!--          <label for="endDateInput" class="label-header" :class="{'gray-color' : startDate === ''}">تا تاریخ</label>-->
<!--          <input id="endDateInput" class="width-90 mr-5 text-align-center" v-model="endDate"/>-->
<!--        </div>-->

<!--        <div class="d-flex align-items-center" style="margin-right: 40px;">-->
<!--          <label for="mainStreet" class="label-header">خیابان اصلی</label>-->
<!--          <select id="mainStreet" v-model="mainStreetID" class="mr-5 width-160" @change="onChangeMainStreet($event)">-->
<!--            <option :value="0">همه</option>-->
<!--            <option v-for="street in mainStreets" v-bind:key="street.id" :value="street.ID">{{ street.Title }}</option>-->
<!--          </select>-->
<!--        </div>-->

<!--        <div class="d-flex align-items-center" style="margin-right: 20px;">-->
<!--          <label for="subStreet" class="label-header">خیابان فرعی</label>-->
<!--          <select id="subStreet" v-model="subStreetID" class="mr-5 width-160" :disabled="mainStreetID === 0">-->
<!--            <option :value="0">همه</option>-->
<!--            <option v-for="street in subStreets" v-bind:key="street.id" :value="street.ID">{{ street.Title }}</option>-->
<!--          </select>-->
<!--        </div>-->

<!--      </div>-->

<!--    </div>-->


    <div v-if="reports.length!==0">
      <div class="table-container">
        <table class="report-table">

          <thead>
          <tr>
            <th>ردیف</th>
            <th>شماره تابلو</th>
            <th>نام و نام خانوادگی</th>
            <th>دکترای</th>
            <th>متخصص</th>
            <th>هزینه تابلو</th>
            <th>هزینه پرداخت</th>
            <th>عملیات</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(n,index) in reports" v-bind:key="n.ID">
            <td>{{ pageIndex + index + 1 }}</td>
            <td>{{ n.ID }}</td>
            <td>{{ n.Person.Alias }}</td>
            <td>{{ n.Person.Expertise.Major.FaName }}</td>
            <td>{{ n.Person.Expertise.Sub.FaName }}</td>
            <td>{{ thousandSeparator(n.TotalPrice) }}</td>
            <td>{{convertGregorianToJalali(n.CreatedAt)  }} ریال</td>
            <td>{{ '100' }} روز</td>
          </tr>
          </tbody>

        </table>
      </div>

      <paginating
          :total-count="$store.getters.getSTotalReports"
          @onPageClick="onGetReportPage">
      </paginating>

    </div>

    <div v-else class="no-data">
      <p class="red-color">گزارشی موجود نیست!</p>
    </div>

    <date-picker
        type="date"
        v-model="startDate"
        format="jYYYY/jMM/jDD"
        element="startDateInput"
        @input="onGetDate($event)"/>

    <date-picker
        type="date"
        v-model="endDate"
        format="jYYYY/jMM/jDD"
        element="endDateInput"
        :min="startDate"
        :disabled="startDate === ''"
        @input="onGetDate($event)"/>

  </div>
</template>

<script>

import paginating from "@/components/helper/paginating/paginating";
import {constMethods} from '@/mixin/constMethods.js'

export default {
  name: "requests",
  components: {
    paginating
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      searchTxtDoc: '',
      mainStreetID: 0,
      subStreetID: 0,
      startDate: '',
      endDate: '',
      pageIndex: 0
    }
  },
  methods: {
    onGetDate() {
      if (new Date(this.startDate) > new Date(this.endDate)) {
        this.endDate = ''
      }
      this.getSRequest(0)
    },
    onGetReportPage(index) {
      this.pageIndex = index
      this.getSRequest(index)
    },
    onChangeMainStreet(mainStreetID) {
      this.subStreetID = 0

      const obj = {}
      obj['Pathway'] = {
        ParentID: parseInt(mainStreetID.target.value)
      }
      this.$store.dispatch("getSubStreets", obj)
    },
    getSRequest(index) {
      const data = {
        filter: {
          "Mobile": "",
          "SubwayID": this.subStreetID,
          "PathwayID": this.mainStreetID,
          "StartDate": this.convertJalaliToGregorian(this.startDate),
          "EndDate": this.convertJalaliToGregorian(this.endDate),
        },
        index: index
      }
      this.$store.dispatch("getSRequests", data)
    }

  },
  computed: {
    mainStreets() {
      return this.$store.getters.getMainStreets
    },
    subStreets() {
      return this.$store.getters.getSubStreets
    },
    reports() {
      return this.$store.getters.getSReports
    },
  },
  mounted() {
    this.$store.dispatch("getMainStreets")
    this.getSRequest(0)
  }

}
</script>

<style scoped>

</style>