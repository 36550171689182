import ReqJson from "@/resource/reqJson";
import Vue from "vue";

const state = {
    boothsList: [],
    totalBoothsCount: 0,
    allBoothsList: [],
    selectedBooth: {},
    boothHistoryList: [],
    totalBoothHistoryCount: 0
};

const getters = {
    getBoothsList(state) {
        return state.boothsList
    },
    getTotalBoothsCount(state) {
        return state.totalBoothsCount
    },
    getAllBoothsList(state) {
        return state.allBoothsList
    },
    getSelectedBooth(state) {
        return state.selectedBooth
    },
    getBoothHistoryList(state) {
        return state.boothHistoryList
    },
    getTotalBoothHistoryCount(state) {
        return state.totalBoothHistoryCount
    },
};

const mutations = {
    setBoothsList(state, boothsList) {
        state.boothsList = boothsList
    },
    setTotalBoothsCount(state, totalBoothsCount) {
        state.totalBoothsCount = totalBoothsCount
    },
    setAllBoothsList(state, allBoothsList) {
        state.allBoothsList = allBoothsList
    },
    setSelectedBooth(state, selectedBooth) {
        state.selectedBooth = selectedBooth
    },
    setBoothHistoryList(state, boothHistoryList) {
        state.boothHistoryList = boothHistoryList
    },
    setTotalBoothHistoryCount(state, totalBoothHistoryCount) {
        state.totalBoothHistoryCount = totalBoothHistoryCount
    },
};

const actions = {

    getBooths({getters, commit}, index) {

        if (index === 0) {
            commit("setBoothsList", []);
        }

        const object = {};
        object['Booth'] = {
            'MarketID': getters.getSelectedMarket.ID
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/booths', body: body})
            .then(response => {

                commit("setBoothsList", response.result);

                if (index === 0)
                    commit("setTotalBoothsCount", response.meta.TotalCount);

            })
    },

    getAllBooths({getters, commit, dispatch}, data) {

        if (data.index === 0)
            commit('setAllBoothsList', [])


        const object = {};
        object['Booth'] = {
            'MarketID': data.marketID
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/booths', body: body})
            .then(response => {

                if (response.result.length > 0) {
                    let i;
                    for (i = 0; i < response.result.length; i++) {
                        getters.getAllBoothsList.push(response.result[i])
                    }

                    if (response.result.length === getters.getLimit) {
                        data.index = getters.getAllBoothsList.length
                        dispatch('getAllBooths', data)
                    }
                }

            })
    },

    uploadBoothsMap({getters}, pic) {

        const fd = new FormData();

        fd.append('image', pic, pic.name);

        this.dispatch('sendRequest', {url: 'Attachment/boot_map/upload?market=' + getters.getSelectedMarket.ID, body: fd})
            .then(response => {
                getters.getSelectedMarket.MapPic = response.result[0].FileID
                Vue.toasted.success('بارگزاری نقشه بازار انجام شد!', {duration: '1000'})
            })
    },

    setBooth({getters}, data) {

        const mainObj = data

        const filter = {}

        return new Promise((resolve) => {

            const body = new ReqJson(getters.getAdminModel, mainObj, filter, 20, 0).reqJS()

            this.dispatch('sendRequest', {url: 'Admin/booth/setBooth', body: body})
                .then((response) => {

                    Vue.toasted.success('ثبت غرفه انجام شد!', {duration: '1000'})

                    //آپدیت لیست کل غرفه ها که اول کار در صفحه mainMarketRequestPage گرفته میشود
                    getters.getAllBoothsList.push(response.result[0])

                    resolve()
                })

        })
    },

    removeBooth({getters, dispatch}, boothID) {

        const object = {};
        object['Booth'] = {
            'ID': boothID
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/removeBooth', body: body})
            .then(() => {
                dispatch('getBooths', 0)
                Vue.toasted.success('حذف غرفه انجام شد!', {duration: '1000'})


                //آپدیت لیست کل غرفه ها که اول کار در صفحه mainMarketRequestPage گرفته میشود
                if (getters.getAllBoothsList.length !== 0) {
                    getters.getAllBoothsList.forEach((el, index) => {
                        if (boothID === el.ID) {
                            getters.getAllBoothsList.splice(index, 1)
                        }
                    })
                }

            })
    },


    getBoothHistory({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = {
            MarketID: data.marketID,
            BoothID: data.boothID
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/boothHistory', body: body})
            .then(response => {
                if (response.meta.TotalCount) {
                    commit("setTotalBoothHistoryCount", response.meta.TotalCount);
                }
                commit('setBoothHistoryList', response.result)
            })
    },


    switchBoothStatus({getters, dispatch}, data) {
        const object = {};
        object['Booth'] = {
            'ID': data.boothID,
            "Active": data.active == 1 ? 0 : 1
        }
        object['FrozenBooth'] = {
            'Name': data.name,
            "Phone": data.phone,
            "Price": data.price,
            "StartDate": data.startDate,
            "EndDate": data.endDate,
        }

        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/switchBoothStatus', body: body})
            .then(response => {

                for (let i = 0; i < getters.getBoothsList.length; i++) {
                    if (getters.getBoothsList[i].ID === data.boothID) {
                        // getters.getBoothsList[i].Active = getters.getBoothsList[i].Active == 1 ? 0 : 1
                        getters.getBoothsList[i].Active = response.result[0].Active
                        getters.getBoothsList[i].FrozenBooth = response.result[0].FrozenBooth
                        break
                    }
                }

                const obj = {
                    marketID: getters.getSelectedMarket.ID,
                    boothID: getters.getSelectedBooth.ID,
                    index: 0
                }

                dispatch("getBoothHistory", obj)

            })
    },

    unFreezeBooth({getters, dispatch}, data) {

        const object = {};

        object['FrozenBooth'] = {
            'ID': data.frozenBoothID,
        }
        const mainObj = object

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/booth/unFreezeBooth', body: body})
            .then(() => {

                const obj = {
                    marketID: getters.getSelectedMarket.ID,
                    boothID: getters.getSelectedBooth.ID,
                    index: 0
                }

                dispatch("getBoothHistory", obj)

            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
