import ReqJson from "@/resource/reqJson";
import {router} from "@/router/router";

const state = {};

const getters = {};

const mutations = {};

const actions = {

    searchCustomer({getters, commit, dispatch}, mobile) {

        const mainObj = {
            "_": ''
        }

        const filter = {
            'Mobile': mobile
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/searchCustomer', body: body})
            .then(response => {

                if (router.currentRoute.path.includes('managerInformation')) {
                    if (response.result.length !== 0) {
                        getters.getSelectedMarket['Manager'] = response.result[0]
                        dispatch('getManagerIdDocumentList')
                    } else {
                        getters.getSelectedMarket['Manager'] = {Name: '', Family: '', Mobile: mobile}
                        dispatch('setModal', {'title': 'مدیری با این شماره در سامانه وجود ندارد!', 'type': 'error', 'button': 2, 'yes': 'تغییر شماره', 'no': 'ادامه'})
                            .then(
                                () => {//تغییر شماره
                                    getters.getSelectedMarket['Manager'] = {Name: '', Family: '', Mobile: ''}
                                },
                                () => {//ادامه
                                    dispatch('fastUser', mobile)
                                }
                            )
                    }
                } else {
                    if (response.result.length !== 0) {
                        commit('setCustomerProfile', response.result[0])
                    } else {
                        commit('setCustomerProfile', {'Mobile': mobile})
                        dispatch('setModal', {'title': 'شخصی با این شماره در سامانه وجود ندارد!', 'type': 'error', 'button': 1})
                    }
                }

            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
