<template>
  <div>

    <div class="row">

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="name" class="label-input">نام پزشک</label>
          <input id="name" v-model="profile.Name" class="w-100" type="text" :class="{'invalid-input':$v.profile.Name.$invalid && formInvalid}" @input="$v.profile.Name.$touch()" maxlength="20">
          <p class="error-text" v-if="$v.profile.Name.$invalid && formInvalid">نام را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="family" class="label-input">نام خانوادگی</label>
          <input id="family" v-model="profile.Family" class="w-100" type="text" :class="{'invalid-input':$v.profile.Family.$invalid && formInvalid}" @input="$v.profile.Family.$touch()" maxlength="20">
          <p class="error-text" v-if="$v.profile.Family.$invalid && formInvalid">نام خانوادگی را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="Ename" class="label-input">نام پزشک(لاتین)</label>
          <input id="Ename" v-model="profile.NameEN" class="w-100" type="text" :class="{'invalid-input':$v.profile.NameEN.$invalid && formInvalid}" @input="$v.profile.NameEN.$touch()" maxlength="20">
          <p class="error-text" v-if="$v.profile.NameEN.$invalid && formInvalid">نام لاتین را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="Efamily" class="label-input">نام خانوادگی(لاتین)</label>
          <input id="Efamily" v-model="profile.FamilyEN" class="w-100" type="text" :class="{'invalid-input':$v.profile.FamilyEN.$invalid && formInvalid}" @input="$v.profile.FamilyEN.$touch()" maxlength="20">
          <p class="error-text" v-if="$v.profile.FamilyEN.$invalid && formInvalid">نام خانوادگی لاتین را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="IDNumber" class="label-input">شماره شناسنامه</label>
          <input id="IDNumber" v-model="profile.IDNumber" class="w-100" type="text" :class="{'invalid-input':$v.profile.IDNumber.$invalid && formInvalid}" @input="$v.profile.IDNumber.$touch()" maxlength="10">
          <p class="error-text" v-if="$v.profile.IDNumber.$invalid && !$v.profile.IDNumber.required && formInvalid">شماره شناسنامه را وارد کنید</p>
          <p class="error-text" v-if="!$v.profile.IDNumber.isNumber && $v.profile.IDNumber.$dirty && formInvalid">شماره شناسنامه اشتباه است</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="NC" class="label-input">کد ملی</label>
          <input id="NC" v-model="profile.NationalCode" class="w-100" type="text" :class="{'invalid-input':$v.profile.NationalCode.$invalid && formInvalid}" @input="$v.profile.NationalCode.$touch()" maxlength="10" :disabled="true">
          <p class="error-text" v-if="$v.profile.NationalCode.$invalid && !$v.profile.NationalCode.required && formInvalid">کد ملی خود را وارد کنید</p>
          <p class="error-text" v-if="!$v.profile.NationalCode.validNationalCode && $v.profile.NationalCode.$dirty  && formInvalid">کد ملی صحیح نیست</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class=" input-row height-70">
          <label for="birthDateInput" class="label-input">تاریخ تولد</label>
          <input v-model="BD" id="birthDateInput" class="w-100" :class="{'invalid-input':formInvalid && !$v.profile.BirthDate.required}"/>
          <p class="error-text" v-if="(formInvalid && !$v.profile.BirthDate.required)">تاریخ تولد را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="mobile" class="label-input">شماره همراه</label>
          <input id="mobile" v-model="profile.Mobile" :disabled="true" maxlength="11" class="w-100" type="text" :class="{'invalid-input':$v.profile.Mobile.$invalid && formInvalid}">
          <p class="error-text" v-if="$v.profile.Mobile.$invalid && !$v.profile.Mobile.required && formInvalid">شماره همراه را وارد کنید</p>
          <p class="error-text" v-else-if="!$v.profile.Mobile.validMobileNumber && $v.profile.Mobile.$dirty && formInvalid">شماره همراه صحیح نیست</p>
        </div>
      </div>


      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="docNum" class="label-input">شماره نظام پزشکی</label>
          <input id="docNum" v-model="profile.MedNumber" class="w-100" type="text" :class="{'invalid-input':$v.profile.MedNumber.$invalid && formInvalid}" @input="$v.profile.MedNumber.$touch()" maxlength="30">
          <p class="error-text" v-if="$v.profile.MedNumber.$invalid && !$v.profile.MedNumber.required && formInvalid">شماره نظام پزشکی را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="majorExs" class="label-input">دکترای</label>
          <select v-model="profile.ExpertiseMID" id="majorExs" class="w-100" :class="{'invalid-input':formInvalid && !$v.profile.ExpertiseMID.required}" @change="onChangeMainEx($event)">
            <option v-for="ex in majorExs" v-bind:key="ex.ID" :value="ex.ID">{{ ex.FaName }}</option>
          </select>
          <p class="error-text" v-if="(formInvalid && !$v.profile.ExpertiseMID.required)">نوع دکترا را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="subExs" class="label-input">متخصص</label>
          <select v-model="profile.ExpertiseSID" id="subExs" class="w-100" :class="{'invalid-input':formInvalid && !$v.profile.ExpertiseSID.required}">
            <option v-for="ex in subExs" v-bind:key="ex.ID" :value="ex.ID">{{ ex.FaName }}</option>
          </select>
          <p class="error-text" v-if="(formInvalid && !$v.profile.ExpertiseSID.required)">نوع تخصص را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="mainStreet" class="label-input">خیابان اصلی مطب</label>
          <select v-model="profile.Address.PathwayID" id="mainStreet" class="w-100" :class="{'invalid-input':formInvalid && !$v.profile.Address.PathwayID.required}" @change="onChangeMainStreet($event)">
            <option v-for="street in mainStreets" v-bind:key="street.ID" :value="street.ID">{{ street.Title }}</option>
          </select>
          <p class="error-text" v-if="(formInvalid && !$v.profile.Address.PathwayID.required)">خیابان اصلی را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="input-row height-70">
          <label for="subStreet" class="label-input">خیابان فرعی مطب</label>
          <select v-model="profile.Address.SubwayID" id="subStreet" class="w-100" :class="{'invalid-input':formInvalid && !$v.profile.Address.SubwayID.required}">
            <option v-for="street in subStreets" v-bind:key="street.ID" :value="street.ID">{{ street.Title }}</option>
          </select>
          <p class="error-text" v-if="(formInvalid && !$v.profile.Address.SubwayID.required)">خیابان فرعی را وارد کنید</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6">
        <div class="  input-row height-70">
          <label for="postCode" class="label-input">کد پستی</label>
          <input id="postCode" v-model="profile.Address.PostCode" class="w-100" type="text" :class="{'invalid-input':$v.profile.Address.PostCode.$invalid && formInvalid}" @input="$v.profile.Address.PostCode.$touch()" maxlength="10">
          <p class="error-text" v-if="$v.profile.Address.PostCode.$invalid && !$v.profile.Address.PostCode.required && formInvalid">کد پستی را وارد کنید</p>
          <p class="error-text" v-if="(!$v.profile.Address.PostCode.isNumber || !$v.profile.Address.PostCode.minLength)&& $v.profile.Address.PostCode.$dirty && formInvalid">کد پستی اشتباه است</p>
        </div>
      </div>


      <div class="col-xl-6 col-md-8">
        <div class="input-row height-70">
          <label for="address" class="label-input">تکمیل آدرس</label>
          <input id="address" v-model="profile.Address.Detail" class="w-100" type="text" :class="{'invalid-input':$v.profile.Address.Detail.$invalid && formInvalid}" @input="$v.profile.Address.Detail.$touch()" maxlength="80">
          <p class="error-text" v-if="$v.profile.Address.Detail.$invalid && formInvalid">آدرس خود را وارد کنید</p>
        </div>
      </div>

    </div>

    <div class="d-flex justify-content-end">
      <div class="btn green-bg width-160 d-flex align-items-center justify-content-center" v-on:click="nextStep">
        <p class="btn-title">تایید و ادامه</p>
      </div>
    </div>

    <date-picker
        type="date"
        v-model="profile.BirthDate"
        format="YYYY-MM-DD"
        element="birthDateInput"
        :max="convertFormat(new Date())"/>

  </div>
</template>

<script>

import {maxLength, minLength, required} from 'vuelidate/lib/validators'
import {validation} from '@/mixin/validation'
import {constMethods} from '@/mixin/constMethods'

export default {
  name: "personalInfo",
  data() {
    return {
      formInvalid: false,
    }
  },
  mixins: [
    validation,
    constMethods
  ],
  methods: {
    onChangeMainStreet(mainStreetID) {
      const obj = {}
      obj['Pathway'] = {
        ParentID: parseInt(mainStreetID.target.value)
      }
      this.$store.dispatch("getSubStreets", obj)
    },
    onChangeMainEx(majorExID) {
      const obj = {}
      obj['Expertise'] = {
        ParentID: parseInt(majorExID.target.value)
      }
      this.$store.dispatch("getSubEx", obj)
    },
    nextStep() {
      if (!this.$v.$invalid) {
        this.$store.dispatch('setModal', {'title': 'آیا اطمینان دارید؟', 'type': 'warning', 'button': 2}).then(
            () => {
              const obj = {
                Name: this.profile.Name,
                Family: this.profile.Family,
                NameEN: this.profile.NameEN,
                FamilyEN: this.profile.FamilyEN,
                IDNumber: this.profile.IDNumber,
                NationalCode: this.profile.NationalCode,
                BirthDate: this.profile.BirthDate,
                Mobile: this.profile.Mobile,
                MedNumber: this.profile.MedNumber,
                ExpertiseMID: this.profile.ExpertiseMID,
                ExpertiseSID: this.profile.ExpertiseSID,
                Address: this.profile.Address,
              }
              this.$store.dispatch("setProfile", obj)
            })
      } else {
        this.formInvalid = true
      }
    },
  },
  validations: {
    profile: {
      Name: {required},
      Family: {required},
      NameEN: {required},
      FamilyEN: {required},
      IDNumber: {
        required,
        isNumber(val) {
          if (val === undefined || val === "") {
            return true
          } else {
            let res = this.convertNumbersToEnglish(val)
            return !isNaN(res)
          }
        },
      },
      NationalCode: {
        required,
        validNationalCode: function (val) {
          if (val === undefined || val === "") {
            return true
          } else {
            let res = this.convertNumbersToEnglish(val)
            return this.validNC(res);
          }
        }
      },
      BirthDate: {required},
      Mobile: {
        required,
        validMobileNumber: function (val) {
          if (val === "") return true
          let number = this.convertNumbersToEnglish(val)
          return this.validMobile(number);
        }
      },
      MedNumber: {required},
      ExpertiseMID: {required},
      ExpertiseSID: {required},
      Address: {
        SubwayID: {required},
        PathwayID: {required},
        Detail: {required},
        PostCode: {
          required,
          isNumber(val) {
            if (val === undefined || val === "") {
              return true
            } else {
              let res = this.convertNumbersToEnglish(val)
              return !isNaN(res)
            }
          },
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
      },
    },
  },
  mounted() {
    this.$store.dispatch("getMainStreets")
    this.$store.dispatch("getMajorEx")
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    },
    BD() {
      return this.convertGregorianToJalali(this.profile.BirthDate)
    },
    mainStreets() {
      return this.$store.getters.getMainStreets
    },
    subStreets() {
      return this.$store.getters.getSubStreets
    },
    majorExs() {
      return this.$store.getters.getMajorExs
    },
    subExs() {
      return this.$store.getters.getSubExs
    },
  }
}
</script>

<style scoped>

</style>