<template>

  <div class=" content-container">

    <dashboard-header></dashboard-header>

    <transition enter-active-class="animate__animated animate__fadeIn">
      <router-view></router-view>
    </transition>

  </div>

</template>

<script>

import dashboardHeader from "@/components/dashboard/sections/content/header/dashboardHeader";

export default {
  name: "dashboardContent",
  components: {
    dashboardHeader
  },
}
</script>

<style scoped>

</style>