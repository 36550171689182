
const state = {
    bearer: 'bearer',
    ACK: 'ACK',
    limit: 10,
    isLoading : false,
    admin : false
};
const getters = {
    getBearer(state) {
        return state.bearer
    },
    getACK(state) {
        return state.ACK
    },
    getLimit(state) {
        return state.limit
    },
    isLoading(state) {
        return state.isLoading
    },
    isAdmin(state) {
        return state.admin
    },
};
const mutations = {
    setLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setAdmin(state, admin) {
        state.admin = admin
    },
};
const actions = {

    downloadPic({commit}, picName) {
        this.dispatch('download', {
            url: 'Attachment/dl?filename=' + picName
        })
            .then(response => {
                commit('setPic',response)
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}