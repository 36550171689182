<template>
  <div class="header d-flex">
    <div class="header-title d-flex">

      <div class="d-flex align-items-center">
        <img class="cursorPointer m-0" v-if="showBackBtn" v-on:click="onClickBack" src="../../../../../assets/images/back.png" alt="">
        <div v-if="showBackBtn" class="divider"></div>
        <img class="img-menu" src="../../../../../assets/images/menu.png" alt="bazaar" v-on:click="menuClick">
        <div class="divider img-menu"></div>
        <img :src="require(`../../../../../assets/images/${icon}`)" alt="bazaar">
      </div>

      <p>{{ title }}</p>
    </div>

    <div class="header-person d-flex">
      <div v-if="!profile.Avatar" class="header-person-img no-img"></div>
      <div class="header-person-img" v-else><img :src="$store.getters.getImageServerURL + profile.Avatar" alt="" class="w-100"></div>

      <div class="header-person-info" v-on:click="onClickProfile" :class="{'cursorPointer' : $router.currentRoute.path === '/bazaar'}">
        <div class="d-flex align-items-top">
          <p class="m-0 ellipsis">{{ profile.Name }}</p>
          <p style="margin: 0 2px"></p>
          <p class="m-0 ellipsis">{{ profile.Family }}</p>
        </div>
        <p v-if="profile.Role" class="header-person-info-position">{{ profile.Role.Title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {menu} from "@/mixin/menu";

export default {
  name: "dashboardHeader",
  data() {
    return {
      title: 'گزارش گیری',
      icon: 'report_h.png',
      showBackBtn: false,
    }
  },
  mixins: [
    menu
  ],
  methods: {
    setHeaderTitle() {
      return 'گزارش گیری'
    },
    setHeaderIcon() {
      return 'report_h.png'
    },
    onClickProfile() {
      if (this.$router.currentRoute.path === '/bazaar')
        if (this.$router.currentRoute.path !== '/profile')
          this.$router.push('/profile')
    },
    onClickBack() {
      this.$router.go(-1)
    },
  },
  watch: {
    $route(to) {
      let r = to.path
      let q = to.query
      this.showBackBtn = !(r === '/bazaar' || r === '/request' || r === '/financial' || r === '/contractors' || r === '/systemUsers' || r === '/reports' || r === '/boothViolation'
          || r === '/basicSettings/addOrgans' || r === '/basicSettings/addAdminDocs' || r === '/basicSettings/addCustomerDocs' || r === '/basicSettings/addMarketCategory');
      this.title = this.setHeaderTitle(r, q)
      this.icon = this.setHeaderIcon(r, q)
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    },
  }
}
</script>

<style scoped>

</style>