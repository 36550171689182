import ReqJson from "@/resource/reqJson";

const state = {
    adminOrgansList: [],
    totalAdminOrgansListCount: 0,
};

const getters = {
    getAdminOrgansList(state) {
        return state.adminOrgansList
    },
    getTotalAdminOrgansCount(state) {
        return state.totalAdminOrgansListCount
    },
};

const mutations = {
    setAdminOrgansList(state, adminOrgansList) {
        state.adminOrgansList = adminOrgansList
    },
    setTotalAdminOrgansCount(state, totalAdminOrgansListCount) {
        state.totalAdminOrgansListCount = totalAdminOrgansListCount
    },
};

const actions = {

    getAdminSupportOrgans({getters, commit, dispatch}, index) {

        if (index === 0)
            commit('setAdminOrgansList', [])

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/getSupportOrgans', body: body})
            .then(response => {

                if (response.result.length > 0) {

                    if (index === 0)
                        commit("setTotalAdminOrgansCount", response.meta.TotalCount);

                    for (let i = 0; i < response.result.length; i++) {
                        getters.getAdminOrgansList.push(response.result[i])
                    }

                    if (response.result.length === getters.getLimit) {
                        index = getters.getAdminOrgansList.length
                        dispatch('getAdminSupportOrgans', index)
                    }
                }
            })
    },

    addAdminSupportOrgans({getters}, item) {

        const mainObj = {
            'SupportOrgan': {
                'ID': item.ID,
                'Title': item.Title,
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/setSupportOrgan', body: body})
            .then((response) => {

                if (item.ID) {
                    for (let i = 0; i < getters.getAdminOrgansList.length; i++) {
                        if (getters.getAdminOrgansList[i].ID === item.ID) {
                            getters.getAdminOrgansList[i] = response.result[0]
                            break
                        }
                    }
                } else {
                    getters.getAdminOrgansList.push(response.result[0])
                }
            })
    },


    deleteAdminSupportOrgan({getters}, supportOrganID) {

        const mainObj = {
            'SupportOrgan': {
                'ID': supportOrganID,
            }
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/delSupportOrgan', body: body})
            .then(() => {
                for (let i = 0; i < getters.getAdminOrgansList.length; i++) {
                    if (getters.getAdminOrgansList[i].ID === supportOrganID) {
                        getters.getAdminOrgansList.splice(i, 1);
                        break
                    }
                }
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
