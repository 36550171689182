import { render, staticRenderFns } from "./enterVerificationCode.vue?vue&type=template&id=b60f4898&scoped=true&"
import script from "./enterVerificationCode.vue?vue&type=script&lang=js&"
export * from "./enterVerificationCode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b60f4898",
  null
  
)

export default component.exports