import ReqJson from "@/resource/reqJson";

const state = {
    reportsList: [],
    totalReportsCount: 0,

    commutingReportsList: [],
    totalCommutingReportsCount: 0,

    reservesReportList: [],

    reportDashboard: {BestBooth: {Booth: {}, Market:{}}},
};

const getters = {
    getReportsList(state) {
        return state.reportsList
    },
    getTotalReportsCount(state) {
        return state.totalReportsCount
    },

    getCommutingReportsList(state) {
        return state.commutingReportsList
    },
    getTotalCommutingReportsCount(state) {
        return state.totalCommutingReportsCount
    },
    getReservesReport(state) {
        return state.reservesReportList
    },

    getReportDashBoard(state) {
        return state.reportDashboard
    },
};

const mutations = {
    setReportsList(state, reportsList) {
        state.reportsList = reportsList
    },
    setTotalReportsCount(state, totalReportsCount) {
        state.totalReportsCount = totalReportsCount
    },

    setCommutingReportsList(state, commutingReportsList) {
        state.commutingReportsList = commutingReportsList
    },
    setTotalCommutingReportsCount(state, totalCommutingReportsCount) {
        state.totalCommutingReportsCount = totalCommutingReportsCount
    },
    setReservesReport(state, reservesReportList) {
        state.reservesReportList = reservesReportList
    },

    setReportDashboard(state, reportDashboard) {
        state.reportDashboard = reportDashboard
    },
};

const actions = {

    getReports({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = data.filter

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/reserve/marketReservationList', body: body})
            .then(response => {
                commit("setReportsList", response.result);
                if (data.index === 0)
                    commit("setTotalReportsCount", response.meta.TotalCount);
            })
    },

    getCommutingReports({getters, commit}, data) {

        const mainObj = {};
        mainObj['Market'] = {
            "ID": data.ID,
        }

        const filter = {
            StartDate: data.startDate,
            EndDate: data.endDate,
            BoothID: data.boothID
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/report/marketCommutingReport', body: body})
            .then(response => {
                commit("setCommutingReportsList", response.result);
                if (data.index === 0)
                    commit("setTotalCommutingReportsCount", response.meta.TotalCount);
            })
    },

    getReserveReport({getters, commit}, data) {

        const mainObj = {};
        mainObj['Market'] = {
            "ID": data.marketID,
        }

        const filter = {
            StartDate: data.startDate,
            EndDate: data.endDate,
            BoothID : data.boothID
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/report/marketResReport', body: body})
            .then(response => {
                commit("setReservesReport", response.result);
            })
    },

    getDashboardReport({getters, commit}, marketID) {

        commit('setReportDashboard', {BestBooth: {Booth: {}, Market:{}}})

        const mainObj = {
            "_": ''
        }

        const filter = {
            MarketID: marketID
        }

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/report/reportDashboard', body: body})
            .then(response => {
                commit('setReportDashboard', response.result[0])
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
