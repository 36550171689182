import ReqJson from "@/resource/reqJson";

const state = {
    customerReserves: [],
    totalCustomerReservesCount: 0,
    selectedCustomerReserve: {}
};

const getters = {
    getCustomerReserves(state) {
        return state.customerReserves
    },
    getTotalCustomerReservesCount(state) {
        return state.totalCustomerReservesCount
    },
    getSelectedCustomerReserve(state) {
        return state.selectedCustomerReserve
    },
};

const mutations = {
    setCustomerReserves(state, customerReserves) {
        state.customerReserves = customerReserves
    },
    setTotalCustomerReservesCount(state, totalCustomerReservesCount) {
        state.totalCustomerReservesCount = totalCustomerReservesCount
    },
    setSelectedCustomerReserve(state, selectedCustomerReserve) {
        state.selectedCustomerReserve = selectedCustomerReserve
    },
};

const actions = {


    getCustomerReserve({getters, commit}, data) {

        const mainObj = {
            "_": ''
        }

        const filter = {
            MarketID: data.marketID
        }

        const body = new ReqJson(getters.getCustomerModel, mainObj, filter, getters.getLimit, data.index).reqJS()

        this.dispatch('sendRequest', {url: 'Customer/myReserves', body: body})
            .then(response => {

                commit("setCustomerReserves", response.result);
                if (data.index === 0) {
                    commit("setTotalCustomerReservesCount", response.meta.TotalCount);
                }

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
