<template>
  <div>
    <div class="register-header">
      <p>ثبت نام جهت دریافت تابلو پزشکی</p>
      <p style="cursor: pointer" v-on:click="logout">خروج</p>
    </div>

    <div class="register-container content-container">
      <register-customer></register-customer>
    </div>

  </div>

</template>

<script>

import registerCustomer from "@/components/register/registerCustomer";
import {store} from "@/store/store";
import {router} from "@/router/router";
import Vue from "vue";

export default {
  name: "registerComponent",
  components: {
    registerCustomer
  },
  methods: {
    logout() {
      Vue.$cookies.set(store.getters.getBearer, null)
      Vue.$cookies.set(store.getters.getACK, null)
      Vue.$cookies.set('status', null)
      if (router.currentRoute.path !== '/login')
        router.replace({path: '/login'});
    }
  },
}
</script>

<style scoped>

</style>