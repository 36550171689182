import ReqJson from "@/resource/reqJson";
import Vue from "vue";

const state = {
    commonSettings: {}
};

const getters = {
    getCommonSettings(state) {
        return state.commonSettings
    },
};

const mutations = {
    setCommonSettings(state, commonSettings) {
        state.commonSettings = commonSettings
    },
};

const actions = {

    getAdminOtherSettings({getters, commit}, index) {

        // if (index === 0)
        //     commit('setAdminOrgansList', [])

        const mainObj = {
            "_": ''
        }

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, index).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/getCommon', body: body})
            .then((response) => {
                commit('setCommonSettings', response.result[0])
            })
    },

    setAdminOtherSettings({getters}, obj) {

        const mainObj = obj

        const filter = {}

        const body = new ReqJson(getters.getAdminModel, mainObj, filter, getters.getLimit, 0).reqJS()

        this.dispatch('sendRequest', {url: 'Admin/setting/setCommon', body: body})
            .then(() => {
                Vue.toasted.success('تنظیمات اعمال شد!', {duration: '1000'})
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
