import Vue from "vue";

const state = {
    mainServer: true,
    hideLogs: true,
    debug: false,
    // mainURL: 'https://bazar.yazd.ir/drsign/api',
    mainURL: 'http://dr.eserv.ir/api',
    localURL: 'http://' + '192.168.4.189' + ":" + '8700',
    appKey: 'Doc8FEPY1VtP7tor',
    imageServerURL: '',
    debugURL: 'XDEBUG_SESSION_START=PHPSTORM',
};

const getters = {
    isMainServer(state) {
        return state.mainServer
    },
    isHideLogs(state) {
        return state.hideLogs
    },
    isDebug(state) {
        return state.debug
    },
    getServerURL(state, getters) {
        return getters.isMainServer ? state.mainURL : state.localURL
    },
    getAppKey(state) {
        return state.appKey
    },
    getImageServerURL(state, getters) {
        return state.imageServerURL + getters.getServerURL + '/Attachment/' + Vue.$cookies.get(getters.getACK) + '/dl?filename='
    },
    getImageCaptchaURL(state, getters) {
        return getters.getServerURL + '/Login/captchaImage?filename='
    },
    getDebugURL(state) {
        return state.debugURL
    },
};

const mutations = {};

const actions = {

    sendRequest({commit}, object) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {

            Vue.http.post(object.url, object.body, {
                progress(e) {
                    Math.round(e.loaded / e.total * 100)
                    // if (e.lengthComputable) {
                    //     console.log(Math.round(e.loaded / e.total * 100) + '%');
                    // }
                }
            })
                .then(
                    response => {
                        commit('setLoading', false)

                        if (response.status === 200) {
                            resolve(response.body)
                        }
                    },
                    error => {
                        commit('setLoading', false)
                        reject(error)
                    });


            // Vue.axios.post(object.url, object.body, {
            //     onUploadProgress: uploadEvent => {
            //         console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
            //     }
            // })
            //     .then(function (response) {
            //         resolve(response.data)
            //     })
            //     .catch(function (error) {
            //         reject(error)
            //     });


        })
    },

    sendRequestGet({commit}, object) {
        return new Promise((resolve, reject) => {

            Vue.http.get(object.url, object.body, {
                progress(e) {
                    Math.round(e.loaded / e.total * 100)
                    // if (e.lengthComputable) {
                    //     console.log(Math.round(e.loaded / e.total * 100) + '%');
                    // }
                }
            })
                .then(
                    response => {
                        commit('setLoading', false)

                        if (response.status === 200) {
                            resolve(response.body)
                        }
                    },
                    error => {
                        commit('setLoading', false)
                        reject(error)
                    });

            // Vue.axios.get(object.url, {
            //     onUploadProgress: uploadEvent => {
            //         console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
            //     }
            // })
            //     .then(function (response) {
            //         resolve(response.data)
            //     })
            //     .catch(function (error) {
            //         reject(error)
            //     });
        })
    }

};

export default {
    state,
    getters,
    mutations,
    actions
}