import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import {Routes} from '@/router/routes'

export const router = new VueRouter({
    routes: Routes,
    mode: 'history',//برای حذف # از روت اصلی
    scrollBehavior(to, from, savePosition) {
        if (savePosition)//تنظیمات مربوط به حفظ پوزیشن صفحه موقع برگشت
            return savePosition

        // //تنضیمات اسکرول به پوزیشن خاص از صفحه
        // if (to.hash) {
        //     return {
        //         selector: to.hash
        //     }
        // }


        // return {//با ورود به هر صفحه صفحه از بالا لود میشه
        //     x: 0,
        //     y: 0
        // }
    }
})
